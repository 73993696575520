import React, { useState } from "react";

export default function ColorCircles({
  product,
  selectedColor,
  setSelectedColor,
  disabled,
}) {
  const handleColorChange = (color) => {
    if (disabled) return;
    setSelectedColor(color);
  };

  const buttonStyle = (colorObj) => {
    if (colorObj.id === selectedColor) {
      return {
        border: "0px",
        backgroundColor: colorObj.hex,
        boxShadow: "0 0 0 2px black",
      };
    } else {
      return { border: `2px solid black`, backgroundColor: colorObj.hex };
    }
  };

  const disabledStyle = {
    opacity: 0.5, // Reduce opacity for disabled state
    cursor: "not-allowed",
  };

  function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "text-black" : "text-white";
  }

  return (
    <div>
      <h2 className="mt-8 text-base text-gray-900">Colors</h2>
      <div className="mt-3 flex select-none flex-wrap items-center gap-4">
        {product.colors.map((colorObj, index) => (
          <label key={index} className="cursor-pointer relative">
            <input
              type="radio"
              name="color"
              value={colorObj.id} // Use color id as the value
              className="sr-only"
              checked={selectedColor === colorObj.id} // Check against color id
              disabled={disabled}
              onChange={() => handleColorChange(colorObj.id)} // Pass color id to the handler
            />
            <div
              className={`w-10 h-10 rounded-full hover:border-primary-blue`}
              style={{
                ...buttonStyle(colorObj),
                ...(disabled && selectedColor !== colorObj.id
                  ? disabledStyle
                  : {}),
              }}
              onClick={() => handleColorChange(colorObj.id)}
            >
              {colorObj.id === selectedColor && (
                <span
                  className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold ${getContrastYIQ(
                    colorObj.hex
                  )}`}
                >
                  ✓
                </span>
              )}
            </div>
          </label>
        ))}
      </div>
    </div>
  );
}
