import React from "react";
import { Link } from "react-router-dom";
import ProductCard from "./ProductCard";
import useProductData from "../../hooks/useProductData";

const ProductList = ({ title, searchQuery }) => {
  const { products, setProducts } = useProductData(searchQuery);

  return (
    <div className="container mx-auto px-4 py-8 sm:px-6 lg:px-8 max-w-8xl mb-16">
      <div className="mx-auto py-8">
        <h2 className="text-2xl font-semibold text-blue-900 text-center">
          {title}
        </h2>
      </div>
      <div className="flex flex-wrap -mx-2">
        {products.map((product, index) => (
          <ProductCard
            key={index}
            link={"/" + product.title + "/" + product.id}
            product={product}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
