//Privacy policy Page

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PrivacyPolicy from "../../components/PrivacyPolicy";

export default function PrivacyPolicyPage() {
  return (
    <div>
      <Header />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}
