export default function mapProduct(product) {
  const mapVariation = (variation) => ({
    id: variation.id,
    storage: variation.storage,
    totalValue: variation.totalValue,
    pricePerQuarter: variation.pricePerQuarter,
    pricePerMonth: variation.pricePerMonth,
    pricePerFortnight: variation.pricePerFortnight,
    pricePerWeek: variation.pricePerWeek,
    pricePerDay: variation.pricePerDay,
    minimumPrice: variation.minimumPrice,
  });

  const mapColor = (color) => ({
    id: color.id,
    name: color.name,
    hex: color.hex,
  });

  const variations = product.variations.map(mapVariation);
  const colors = product.colors.map(mapColor);
  const images = product.images.sort((a, b) => a.orderNumber - b.orderNumber);
  product.rentalPeriods.forEach((element, index) => {
    if(element == 'Daily'){
      product.rentalPeriods[index] = 'Half Yearly'
    } else if (element == 'Quarterly') {
      product.rentalPeriods[index] = 'Yearly'
    }
  });
  return {
    ...product,
    id: product.id,
    image: product.icon,
    title: product.name ?? "",
    minPrice: product.minPrice,
    images: images.map((image) => image.data),
    colors: colors,
    storage: [...new Set(variations.map((variation) => variation.storage))],
    variations: variations,
    rentalPeriods: product.rentalPeriods,
    description1: product.description1,
    description2: product.description2,
    inTheBox: product.inTheBox,
    bestSeller: product.bestSeller,
    monthUpFront: product.monthUpFront,
  };
}

export const mapProducts = (products) => {
  return products.map(mapProduct);
};
