import React from "react";
import SupportIcon from "../assets/SupportIcon.png";
import WarrantyIcon from "../assets/WarrantyIcon.png";
import DamageCoverIcon from "../assets/DamageCoverIcon.png";

export default function WhatsIncluded() {
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
      <div className="grid items-center justify-center grid-cols-1 p-6 bg-white rounded-md shadow-lg">
        <img
          src={DamageCoverIcon}
          alt="Damage Cover Icon"
          className="w-24 h-24 mx-auto"
        />
        <h3 className="py-4 text-xl font-semibold text-center text-blue-900">
          Optional Damage Cover
        </h3>
        <p className="text-center text-gray-600">
          With Damage cover, you're protected against accidents.
          We’ll take care of any repairs, so you can stay connected and worry-free!
        </p>
      </div>
      <div className="grid items-center justify-center grid-cols-1 p-6 bg-white rounded-md shadow-lg">
        <img src={WarrantyIcon} alt="Warranty Icon" className="w-24 h-24 mx-auto" />
        <h3 className="py-4 text-xl font-semibold text-center text-blue-900">
          Unlimited Warranty
        </h3>
        <p className="text-center text-gray-600">
          Your FlexiPhone device is protected under an unlimited warranty for
          the duration of your subscription. Any issues, big or small, are
          covered, ensuring your device performs perfectly.
        </p>
      </div>
      <div className="grid items-center justify-center grid-cols-1 p-6 bg-white rounded-md shadow-lg">
        <img src={SupportIcon} alt="Support Icon" className="w-24 h-24 mx-auto" />
        <h3 className="py-4 text-xl font-semibold text-center text-blue-900">
          Extensive Support
        </h3>
        <p className="text-center text-gray-600">
          Our support team is always ready to assist. From setup to
          troubleshooting your subscription device, get quick, comprehensive
          help anytime you need it. Just give us a call.
        </p>
      </div>
    </div>
  );
}
