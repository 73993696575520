import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TOC from "../../components/TOC";

export default function PrivacyPolicyPage() {
  return (
    <div>
      <Header />
      <TOC />
      <Footer />
    </div>
  );
}
