import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const SwapAnimationComponent = () => {
  const [isSwapped, setIsSwapped] = useState(false);
  const paymentFrequency = isSwapped ? "weekly" : "fortnightly";
  const position = isSwapped ? 50 : 0;

  const toggleSwap = () => {
    
  };

  

  useEffect(() => {
    //toggleSwap function every 5 seconds
    const interval = setInterval(() => {
      setIsSwapped(isSwapped => !isSwapped);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="max-w-4xl py-8 mx-auto">
        <div className="text-2xl font-semibold text-center">
          How the payments work for{" "}
          <motion.div
            key={paymentFrequency}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="text-blue-600"
          >
            {paymentFrequency}
          </motion.div>
        </div>
      </div>
      <div className="w-full max-w-4xl px-4 py-12 mx-auto mb-12 md:w-4/5">
        <div className="grid grid-cols-8 gap-1">
          {/* Blue section */}
          <div className="col-span-4 py-2 text-center bg-blue-500 border-l-4 border-blue-600 rounded-md md:px-4 text-blue-950">
            First Payment
          </div>

          {/* Green sections slide in from the bottom initially */}
          <motion.div
            className="col-span-1 px-4 py-2 text-center text-green-900 bg-green-500 border-l-4 border-green-600 rounded-md"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: position - 50, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            2
          </motion.div>
          <motion.div
            className="col-span-1 px-4 py-2 text-center text-green-900 bg-green-500 border-l-4 border-green-600 rounded-md"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: position - 50, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            3
          </motion.div>
          <motion.div
            className="col-span-1 px-4 py-2 text-center text-green-900 bg-green-500 border-l-4 border-green-600 rounded-md"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: position - 50, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            4
          </motion.div>
          <motion.div
            className="col-span-1 px-4 py-2 text-center text-green-900 bg-green-500 border-l-4 border-green-600 rounded-md bg-gradient-to-r from-green-500 to-green-200"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: position - 50, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            ...
          </motion.div>

          <div className="col-span-4"></div>

          {/* Orange sections slide in from the bottom when swapped */}

          <motion.div
            className="col-span-2 px-4 py-2 text-center text-orange-900 bg-orange-500 border-l-4 border-orange-600 rounded-md"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: position - 44, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            2
          </motion.div>
          <motion.div
            className="col-span-2 px-4 py-2 text-center text-orange-900 bg-orange-500 border-l-4 border-orange-600 rounded-md bg-gradient-to-r from-orange-500 to-orange-200"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: position - 44, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            3
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default SwapAnimationComponent;
