import React from "react";
import Login from "../../components/Login";

//login page
export default function LoginPage() {
    return (
        <div>
            <Login />
        </div>
    );
}