import Footer from "../../components/Footer";
import Header from "../../components/Header";

export const TermsOfUsePage = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center items-center py-16 bg-gray-100 px-4">
        <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow">
          <h1 className="text-xl font-bold mb-4">Website Terms of Use</h1>

          <p className="mb-2">
            These Terms and Conditions of Use ("Terms of Use") apply to the
            FlexiPhone website located at FlexiPhone.com.au, its subsidiaries,
            and affiliates. BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE;
            IF YOU DO NOT AGREE, DO NOT USE THE SITE.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Ownership of Site; Agreement to Terms of Use
          </h2>
          <p className="mb-2">
            The Site is the property of AtlasTech Solutions Pty Ltd.
            ("FlexiPhone") and its licensors. FlexiPhone reserves the right to
            change, modify, add, or remove portions of these Terms of Use at any
            time.
          </p>

          <h2 className="text-lg font-semibold my-4">Content</h2>
          <p className="mb-2">
            All content on the Site, including text, graphics, logos, and
            photographs, is owned, controlled, or licensed by FlexiPhone.
          </p>

          <h2 className="text-lg font-semibold my-4">Your Use of the Site</h2>
          <p className="mb-2">
            You agree not to use any device, software, or routine that
            interferes with the proper working of the Site. You may not use the
            Site for any purpose that is unlawful or prohibited by these Terms
            of Use.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Accounts, Passwords, and Security
          </h2>
          <p className="mb-2">
            Certain features or services offered on or through the Site may
            require you to open an account. You are entirely responsible for
            maintaining the confidentiality of your account information,
            including your password.
          </p>

          <h2 className="text-lg font-semibold my-4">Privacy</h2>
          <p className="mb-2">
            FlexiPhone's Privacy Policy applies to use of this Site, and its
            terms are incorporated by reference into these Terms of Use.
          </p>

          <h2 className="text-lg font-semibold my-4">Links to Other Sites</h2>
          <p className="mb-2">
            This Site may contain links to other independent third-party Web
            sites. FlexiPhone is not responsible for and does not endorse the
            content of such Linked Sites.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Disclaimers and Limitation of Liability
          </h2>
          <p className="mb-2">
            FlexiPhone does not promise that the Site will be error-free or
            uninterrupted. The Site and its content are delivered on an "as-is"
            and "as-available" basis.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Governing Law; Dispute Resolution
          </h2>
          <p className="mb-2">
            All matters relating to your access to or use of the Site will be
            governed by the laws of the Australian Capital Territory, Australia.
          </p>

          <p className="mb-2 italic">Last Updated: 11/12/2023</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
