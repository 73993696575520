// WithTracking.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import posthog from "posthog-js";

const WithTracking = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    posthog.capture("pageview", { path: location.pathname });
  }, [location]);

  return <>{children}</>;
};

export default WithTracking;
