import React from "react";
import iphone13 from "../assets/iphone-13.jpg";

const HeaderBanner = () => {
  return (
    <div className="bg-gray-100">
      <div className="grid items-center grid-cols-1 gap-4 p-6 mx-auto max-w-7xl md:pl-16 md:grid-cols-2">
        <div className="order-2 md:order-1">
          <h1 className="mb-2 text-4xl font-bold text-center md:text-left">
            Subscribe to your next phone.
          </h1>
          <p className="max-w-xl mb-4 text-sm text-center text-gray-600 md:text-left">
            A simple subscription plan that includes your device, optional
            damage cover, unlimited warranty, and top-notch support all in the
            once place.
            <br /><br />
            No deposit, membership, or establishment fees.
            <br /><br />
            Minimum subscription period is only 28 days.
          </p>
          <div className="flex justify-center md:justify-start">
            <a
              href="/products"
              className="px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-600"
            >
              See the collection
            </a>
          </div>
        </div>
        <div className="relative order-1 md:order-2">
          <img src={iphone13} alt="iPhone 12" className="w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
