//https://tailwindui.com/components/application-ui/forms/form-layouts
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageNumbers from "../../pages/ApplyProcess/PageNumbers";
import { CardElement } from "@stripe/react-stripe-js";
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";
import { API_BASE_URL } from "../../config";
import { wait } from "@testing-library/user-event/dist/utils";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function ApplyForm({ to, product }) {
  const [isSameAsResidentialAddress, setIsSameAsResidentialAddress] =
    useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [customerData, setCustomerData] = useState({
    title: "",
    "first-name": "",
    "middle-name": "",
    "last-name": "",
    gender: "",
    dateOfBirth: "",
    phone: "",
    email: "",
    home: {
      street1: "",
      city: "",
      state: "",
      postal: "",
    },
    delivery: {
      street1: "",
      city: "",
      state: "",
      postal: "",
    },
    privacyPolicy: false,
  });

  const { title, selectedPrice, selectedRentalPeriod, color, storage } =
    product;
  const [image, setImage] = useState(null);

  useEffect(() => {
    isCustomerDataComplete(customerData);
  }, [customerData]);
  

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          API_BASE_URL + `/api/v1/product/${product.id}`
        );
        if (response.ok) {
          const data = await response.json();
          const iconBase64 = data.icon; // Extract the base64-encoded icon
          const iconUrl = `data:image/png;base64,${iconBase64}`; // Create a data URL

          setImage(iconUrl);
        } else {
          // Handle the case when the response is not ok
          console.error("Failed to fetch image");
        }
      } catch (error) {
        // Handle network or other errors
        console.error("Error:", error);
      }
    };

    // Call the async function to fetch the image
    fetchImage();
  }, [product.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the input name starts with 'home-' or 'delivery-'
    if (name.startsWith("home-") || name.startsWith("delivery-")) {
      const [addressType, fieldName] = name.split("-");
      setCustomerData((prevData) => ({
        ...prevData,
        [addressType]: {
          ...prevData[addressType],
          [fieldName]: value,
        },
      }));

      // Check if isSameAsResidentialAddress is true
      if (isSameAsResidentialAddress) {
        setCustomerData((prevData) => ({
          ...prevData,
          delivery: {
            ...prevData.home,
          },
        }));
      }
    } else {
      setCustomerData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    console.log(validationErrors)
  };

  function isCustomerDataComplete(customerData) {
    const requiredFields = [
      "title",
      "first-name",
      "last-name",
      "gender",
      "dateOfBirth",
      "phone",
      "email",
      "home.street1",
      "home.city",
      "home.state",
      "home.postal",
      "delivery.street1",
      "delivery.city",
      "delivery.state",
      "delivery.postal",
    ];
    const newValidationErrors = {};

    // Check if any of the required fields are empty
    for (const field of requiredFields) {
      if (!deepCheckField(customerData, field)) {
        // Splitting the field for nested object validation
        const fieldParts = field.split(".");
        let errorField = fieldParts[0];
        if (fieldParts.length > 1) {
          errorField += "-" + fieldParts[1];
        }
        newValidationErrors[errorField] = "This field is required";
      }
    }

    if (customerData.privacyPolicy === false) {
      newValidationErrors.privacyPolicy =
        "Please agree to the privacy policy to continue.";
    }

    if (customerData.email !== customerData.email2) {
      newValidationErrors.email = "Emails do not match";
      newValidationErrors.email2 = "Emails do not match";
    }

    setValidationErrors(newValidationErrors);

    return Object.keys(newValidationErrors).length === 0;
  }

  function deepCheckField(object, fieldPath) {
    const fieldSegments = fieldPath.split(".");

    let currentObject = object;
    for (const segment of fieldSegments) {
      if (currentObject && currentObject.hasOwnProperty(segment)) {
        currentObject = currentObject[segment];
      } else {
        return false;
      }
    }

    return !!currentObject;
  }

  /**
   * Submits the data to the backend to create the stripe account and customer application
   * @returns
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isCustomerDataComplete(customerData)) {
      return;
    }

    if (isLoading) {
      return;
    }

    try {
      console.log(
        "About to send REST request with customer data: " +
          JSON.stringify(customerData)
      );
      setIsLoading(true);
      // Convert customerData to JSON
      const requestData = JSON.stringify(customerData);
      console.log(requestData);

      // Send a POST request to your createCustomer endpoint
      const response = await fetch(
        API_BASE_URL + "/api/v1/payment/create-customer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestData,
        }
      );

      // Parse the response as JSON
      const responseData = await response.json();

      if (response.ok) {
        // Handle success (e.g., show a success message)
        console.log(responseData);
        //navigate to identification page
        createApplication(responseData);
      } else {
        // Handle errors (e.g., show an error message)
        console.error("Error creating customer:", responseData);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle other errors (e.g., network errors)
      console.error("Error:", error);
      alert(
        "Error: Please check all your input fields and ensure that you have entered a valid email address."
      );
      setIsLoading(false);
    }
  };

  async function createApplication(responseData) {
    const applicationData = {
      userId: responseData.serverId,
      productId: product.id,
      dateOfApplication: new Date(),
      variationId: product.variation.id,
      paymentFrequency: product.selectedRentalPeriod,
      agreedToPrivacyPolicy: true,
      applicationStatus: "INCOMPLETE",
      colorAppliedFor: product.selectedColor,
    };

    console.log("Application Data for application:");
    console.log(applicationData);

    const requestData = JSON.stringify(applicationData);
    try {
      const response = await fetch(
        API_BASE_URL + "/api/v1/application/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestData,
        }
      );

      // Parse the response as JSON
      const responseDataApplication = await response.json();

      if (response.ok) {
        // Handle success (e.g., show a success message)
        console.log("ResponseDataApplication:");
        console.log(responseDataApplication);
        //navigate to identification page
        navigate(to, {
          state: {
            userId: responseData.serverId,
            stripeId: responseData.stripeId,
            product: product,
            applicationId: responseDataApplication.application.id,
          },
        });
      } else {
        // Handle errors (e.g., show an error message)
        console.error("Error creating customer:", responseData);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle other errors (e.g., network errors)
      console.error("Error:", error);
      alert(
        "Error: Please check all your input fields and ensure that you have entered a valid email address."
      );
      setIsLoading(false);
    }
  }

  const handlePhoneInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const personalInfomation = () => {
    return (
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Personal Information (as per ID)
        </h2>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-2 sm:col-start-1">
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Title
            </label>
            <div className="mt-2">
              <select
                id="title"
                name="title"
                autoComplete="honorific-prefix"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:max-w-xs sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              >
                <option value="">Select Title</option>
                <option value="mr">Mr.</option>
                <option value="mrs">Mrs.</option>
                <option value="miss">Miss</option>
                <option value="ms">Ms.</option>
                <option value="dr">Dr.</option>
                <option value="prof">Prof.</option>
              </select>
              {(validationErrors["title"] != null) && (
                        <p className="text-red-500 text-sm mt-1">
                          {validationErrors["title"]}
                        </p>
                      )}
            </div>
          </div>
          <div className="sm:col-span-5 sm:col-start-1">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors["first-name"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["first-name"]}
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-5 sm:col-start-1">
            <label
              htmlFor="middle-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Middle name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="middle-name"
                id="middle-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors["middle-name"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["middle-name"]}
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-5">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors["last-name"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["last-name"]}
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2 sm:col-start-1">
            <label
              htmlFor="gender"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Gender
            </label>
            <div className="mt-2">
              <select
                id="gender"
                name="gender"
                autoComplete="sex"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:max-w-xs sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
                <option value="prefer-not-to-say">Prefer Not To Say</option>
              </select>
              {validationErrors["gender"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["gender"]}
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="dateOfBirth"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Date Of Birth
            </label>
            <div className="mt-2">
              <input
                type="date"
                name="dateOfBirth"
                id="dateOfBirth"
                autoComplete="bday"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors["dateOfBirth"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["dateOfBirth"]}
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-5">
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Phone number
            </label>
            <div className="mt-2">
              <input
                id="phone"
                name="phone"
                type="tel" // Use type "tel" for phone numbers
                autoComplete="tel"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onInput={handlePhoneInput} // Add the input event handler
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors["phone"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["phone"]}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  function accountForm() {
    return (
      <div className="border-b border-gray-900/10 pb-12 pt-12 space-y-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Account Information
        </h2>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors["email"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["email"]}
                </p>
              )}
            </div>
            <div className="mt-2">
              <input
                id="email2"
                name="email2"
                type="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors["email"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors["email"]}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function addressForm(title, addressType) {
    return (
      <>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {title}
        </h2>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <label
              htmlFor={addressType + "-street1"}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Street address
            </label>
            <div className="mt-2">
              <input
                type="text"
                name={addressType + "-street1"}
                id={addressType + "-street1"}
                autoComplete={addressType + "-street1"}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors[addressType + "-street1"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors[addressType + "-street1"]}
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2 sm:col-start-1">
            <label
              htmlFor={addressType + "-city"}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              City
            </label>
            <div className="mt-2">
              <input
                type="text"
                name={addressType + "-city"}
                id={addressType + "-city"}
                autoComplete="address-level2"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors[addressType + "-city"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors[addressType + "-city"]}
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor={addressType + "-state"}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              State
            </label>
            <div className="mt-2">
              <select
                id={addressType + "-state"}
                name={addressType + "-state"}
                autoComplete="state-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:max-w-xs sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              >
                <option>Select State</option>
                <option>ACT</option>
                <option>NSW</option>
                <option>TAS</option>
                <option>QLD</option>
                <option>SA</option>
                <option>WA</option>
                <option>VIC</option>
              </select>
              {validationErrors[addressType + "-state"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors[addressType + "-state"]}
                </p>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor={addressType + "-postal-code"}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              ZIP / Postal code
            </label>
            <div className="mt-2">
              <input
                type="text"
                name={addressType + "-postal-code"}
                id={addressType + "-postal-code"}
                autoComplete={addressType + "-postal-code"}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6"
                onChange={handleInputChange} // Add onChange here
              />
              {validationErrors[addressType + "-postal-code"] && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors[addressType + "-postal-code"]}
                </p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  const addressInformation = () => {
    return (
      <div className="pb-12 pt-12 space-y-12">
        {addressForm("Residential Address (as per ID)", "home")}
        <fieldset>
          <div className="mt-6 space-y-6">
            <div className="relative flex gap-x-3">
              <div className="flex h-6 items-center">
                <input
                  id="isSameAsResidentialAddress"
                  name="isSameAsResidentialAddress"
                  type="checkbox"
                  checked={isSameAsResidentialAddress}
                  onChange={() =>
                    setIsSameAsResidentialAddress(!isSameAsResidentialAddress)
                  }
                  className="h-4 w-4 rounded border-gray-300 text-primary-blue focus:ring-primary-blue"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="offers" className="font-medium text-gray-900">
                  Delivery same as residential address
                </label>
              </div>
            </div>
          </div>
          {isSameAsResidentialAddress ? null : postalAddressInformation()}
        </fieldset>
      </div>
    );
  };

  const postalAddressInformation = () => {
    return (
      <div className="py-8">{addressForm("Postal Address", "delivery")}</div>
    );
  };

  const getUpfrontPaymentMessage = () => {
    switch (selectedRentalPeriod) {
      case "Weekly":
        return `First month at $${selectedPrice * 4} then`;
      case "Fortnightly":
        return `First month at $${selectedPrice * 2} then`;
      default:
        return "";
    }
  };

  return (
    <div>
      <div>
        <PageNumbers totalPages={4} currentPage={1} />
      </div>

      <form
        className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
        onSubmit={handleSubmit}
      >
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            {personalInfomation()}
            {accountForm()}
            {addressInformation()}
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 space-y-10">
              <fieldset>
                <div className="mt-6 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="privacyPolicy"
                        name="privacyPolicy"
                        type="checkbox"
                        onChange={handleInputChange}
                        className="h-4 w-4 rounded border-gray-300 text-primary-blue focus:ring-primary-blue"
                      />
                      {(validationErrors["privacyPolicy"] != null) && (
                        <p className="text-red-500 text-sm mt-1">
                          {validationErrors["privacyPolicy"]}
                        </p>
                      )}
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="privacyPolicy"
                        className="font-medium text-gray-900"
                      >
                        Privacy Policy
                      </label>
                      <p className="text-gray-500">
                        By selecting this check box you agree to our{" "}
                        <a
                          className="text-blue-500"
                          rel="noreferrer"
                          target="_blank"
                          href="/privacy-policy"
                        >
                          privacy policy
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className={`mx-auto md:mx-0 w-full md:w-auto rounded-md px-16 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                isLoading
                  ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                  : "bg-primary-blue text-white hover:bg-indigo-500"
              }`}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Continue
            </button>
          </div>
        </div>

        <div className="mx-auto bg-white shadow-md p-8 m-8 rounded-lg w-full max-w-md h-fit">
          {/* Image */}
          <img
            src={image}
            alt={title} // Use product.title as the alt text
            className="min-w-32 min-h-32 object-cover rounded mx-auto mb-6"
          />

          {/* Product Info */}
          <div className="text-center">
            {console.log(product)}
            <h2 className="text-xl font-semibold">
              {product.title === "" ? "IPhone 14" : product.title}
            </h2>
            <p className="text-gray-500">
              Color: {color ? color.name : ""}
              <br />
              Storage: {storage}
              <br />
              Price: { product.monthUpFront ? getUpfrontPaymentMessage() : "" }  ${selectedPrice} ({selectedRentalPeriod})
            </p>
            <p className="text-gray-500 mt-8">
              Payment will not be deducted until your Subscription application
              has been approved
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}
