//Apply Page

import { useLocation } from "react-router-dom";
import ApplyForm from "../../components/ApplyProcess/ApplyForm";
import { useEffect } from "react";

export default function ApplyPage() {
  const state = useLocation().state;
  const product = state.product;

  useEffect(() => {
    console.log("apply page product");
    console.log(product);
    console.log("apply page state");
    console.log(state);
  });

  return (
    <div className="flex max-w-7xl mx-auto p-16 lg:p-32 ">
      <ApplyForm
        to="/identificationInformation"
        product={product}
      />
    </div>
  );
}
