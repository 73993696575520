import React, { useRef, useEffect, useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageNumbers from "./PageNumbers";
import FileUpload from "../../components/ApplyProcess/FileUpload";
import useFormUpload from "../../hooks/useFormUpload";
import { API_BASE_URL } from "../../config";

export default function IdentificationInformationPage() {
  const { state } = useLocation();
  const userId = state.userId;
  const stripeId = state.stripeId;
  const product = state.product;
  const applicationId = state.applicationId;
  const [idDocument, setIdDocument] = useState(null);
  const [poi, setPoi] = useState(null);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    console.log("identification page stripe id: " + stripeId);
    console.log("Application id: " + applicationId);
  });

  const { response, error, isLoading, uploadData } = useFormUpload(
    API_BASE_URL + `/api/v1/payment/${userId}/uploadDocuments`
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(poi);
    console.log(idDocument);
    if (!idDocument || !poi) {
      alert("Please upload both files");
      return;
    }

    const formData = new FormData();
    formData.append("files", idDocument);
    formData.append("files", poi);
    formData.append("categories", ["ID", "POI"]);

    uploadData(formData)
      .then((response) => {
        // The promise resolved successfully
        console.log(response);
      })
      .then(() => {
        navigate("/termsandconditions", {
          state: {
            userId: userId,
            stripeId: stripeId,
            product: product,
            applicationId: applicationId,
          },
        });
      })
      .catch((error) => {
        // The promise was rejected, handle the error
        console.log(error);
      });
  };

  const identificationInformation = (to, userId) => {
    return (
      <div className="flex mx-auto">
        <form className="mx-auto" onSubmit={handleSubmit}>
          <PageNumbers totalPages={4} currentPage={2} />
          <div className="text-center max-w-xl mx-auto py-16">
            <h1 className="text-xl font-semibold py-4 mx-auto">
              Our Guarantees with Your Data
            </h1>
            <ol className="list-inside list-decimal text-md font-light text-gray-600 mx-auto space-y-2">
              <li className="inline-block w-full">
                We will never sell your data to third parties.
              </li>
              <li className="inline-block w-full">
                We will never use your data for marketing purposes.
              </li>
              <li className="inline-block w-full">
                All data is encrypted with Enterprise level security.
              </li>
            </ol>
          </div>

          <div className="lg:grid lg:grid-cols-2 lg:gap-x-0 lg:gap-y-12 xl:gap-x-0 max-w-6xl">
            <FileUpload
              fileSetReference={setIdDocument}
              title="Identification Document Photo"
              description="Upload a picture of your Identification document"
              typeAndLimit="PNG, JPG, GIF, PDF up to 10MB"
              fileUpload="ID"
            />
            <div className="lg:pt-8 pb-10 lg:pb-0">
              <div className="bg-gray-100 px-4 rounded-b-md lg:rounded-r-lg h-full pb-2 lg:pb-0">
                <h1 className="text-xl font-semibold py-4 mx-auto text-center">
                  What counts as an Identity document?
                </h1>
                <ul className="list-disc list-inside text-md text-gray-700 px-6 mb-8">
                  <li>Passport</li>
                  <li>Driver's License</li>
                  <li>Birth Certificate</li>
                </ul>
              </div>
            </div>

            <FileUpload
              fileSetReference={setPoi}
              title="Proof of Income"
              description="Upload a picture of your Proof of Income"
              typeAndLimit="PNG, JPG, GIF, PDF up to 10MB"
              fileUpload="POI"
            />
            <div className="lg:pt-8 pb-10 lg:pb-0">
              <div className="bg-gray-100 px-4 lg:rounded-l-none rounded-b-md  lg:rounded-r-lg h-full pb-2 lg:pb-0">
                <h1 className="text-xl font-semibold py-4 mx-auto text-center">
                  What counts as Proof of Income?
                </h1>
                <ul className="list-disc list-inside text-md text-gray-700 px-6 mb-8">
                  <li>Bank statements</li>
                  <li>Letter from employer</li>
                  <li>Benefits statements</li>
                </ul>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6 mx-auto lg:col-span-full">
              <button
                type="submit"
                className="rounded-md bg-primary-blue px-3 lg:px-16 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-blue"
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="flex max-w-7xl mx-auto p-16 lg:p-16 ">
        {identificationInformation("/termsandconditions")}
      </div>
    </>
  );
}
