import React, { useEffect, useState } from "react";
import PageNumbers from "./PageNumbers";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import posthog from "posthog-js";
import { getUpfrontPaymentMessage } from "../../components/Product/ProductDisplay";

export let customerReviews = [
  {
    id: 1,
    score: 5,
    name: "Bianca Pavlic",
    title: "Flexiphone makes having a mobile phone easy!",
    description: `Flexiphone makes having a mobile phone easy!!
    
    Flexiphone has provided me with amazing customer service, easy accessibility and a plan that suits my needs.`
  },
  {
    id: 2,
    score: 5,
    name: "David",
    title: "Extremely professional and helpful",
    description: `Extremely professional and helpful, cannot recommend enough!`,
  },
  {
    id: 3,
    score: 5,
    name: "Jacob S",
    title: "Amazing service",
    description: `The team were so extremely helpful and made the whole experience easy and enjoyable.
    
    Couldn't recommend them more!!!!`,
  },
  {
    id: 4,
    score: 5,
    name: "Mandy Aloese",
    title: "100% would recommend!",
    description:
      "Kyle and the team at Flexiphone have been nothing short of amazing! Their service is great, response time is extremely fast and I’m beyond happy with the phone I purchased. Would definitely recommend.",
  },
  {
    id: 5,
    score: 5,
    name: "Wen",
    title: "Excellent service",
    description:
      "Excellent service if you want to have an efficient and reliable upgrade of your phone. Highly recommend! :)",
  },
  {
    id: 6,
    score: 5,
    name: "Ogevasa T",
    title: "To Kyle of Flexiphone",
    description:
      "To Kyle of Flexi phone, Your service is very friendly, efficient and professional. We'll definitely call you again when in need again. Fantastic service. You put smile on my family faces.",
  },
  {
    id: 7,
    score: 5,
    name: "Consumer",
    title: "Kyle and the boys are phenomenal",
    description:
      "Kyle and the boys are phenomenal gentlemen, they helped me find a phone that suits my wants and needs. I’ve been trying to record cinematic videos and needed a phone with good quality and storage.",
  },
  {
    id: 8,
    score: 5,
    name: "Ava L",
    title: "Incredibly friendly and helpful",
    description:
      "Incredibly friendly and helpful. Happily answered all my questions. Great customer service and a great business idea. Can highly recommend. Very easy people to deal with and website is user friendly and easy to navigate.",
  },
  {
    id: 8,
    score: 5,
    name: "Dianne Hadley",
    title: "Great customer service",
    description:
      "Great customer service Kyle was really helpful would recommend Dylan was also very helpful both very friendly and trustworthy",
  },
  {
    id: 8,
    score: 5,
    name: "George Elliott",
    title: "Seamless Service",
    description:
      "Seamless service with unbelievable communication! I water damaged my phone at the beach and was able to get a new one sorted within the same day with these guys! Highly recommend :)",
  },
];

//https://stripe.com/docs/billing/subscriptions/build-subscriptions?ui=elements
function PaymentPage() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();

  const userId = location.state.userId;
  const stripeId = location.state.stripeId;
  const product = location.state.product;
  const applicationId = location.state.applicationId;
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          API_BASE_URL + `/api/v1/product/${product.id}`
        );
        if (response.ok) {
          const data = await response.json();
          const iconBase64 = data.icon; // Extract the base64-encoded icon
          const iconUrl = `data:image/png;base64,${iconBase64}`; // Create a data URL

          setImage(iconUrl);
        } else {
          // Handle the case when the response is not ok
          console.error("Failed to fetch image");
        }
      } catch (error) {
        // Handle network or other errors
        console.error("Error:", error);
      }
    };

    // Call the async function to fetch the image
    fetchImage();
  }, [product.id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    posthog.capture("complete_clicked", {
      // Additional properties can be added here
      label: "Complete Button",
      timestamp: new Date().toISOString(),
    });

    if (!stripe || !elements) {
      setIsSubmitting(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("Error submiting payment information to stripe");
      console.error(error);
      setIsSubmitting(false);
      return;
    } else {
      try {
        const { id } = paymentMethod;
        const response = await fetch(
          API_BASE_URL + "/api/v1/payment/save-payment-method",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ paymentMethodId: id, customerId: stripeId }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        console.log(response);
        completeApplication();
      } catch (error) {
        console.error(error);
      }
    }
    setIsSubmitting(false);
  };

  async function completeApplication() {
    try {
      const response = await fetch(
        API_BASE_URL + `/api/v1/application/${applicationId}/pendingapproval`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log(response);
      navigate("/thankYou");
    } catch (error) {
      console.error(error);
    }
  }

  const { title, selectedPrice, selectedRentalPeriod, color, storage } =
    product;

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
        border: "1px solid #e2e8f0",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const getUpfrontPaymentMessage = () => {
    switch (selectedRentalPeriod) {
      case "Weekly":
        return `First month at $${selectedPrice * 4} then`;
      case "Fortnightly":
        return `First month at $${selectedPrice * 2} then`;
      default:
        return "";
    }
  };

  return (
    <div className="max-w-5xl py-16 mx-auto">
      <PageNumbers totalPages={4} currentPage={4} />
      {/* Title */}
      <h1 className="py-8 mx-auto text-3xl font-semibold text-center">
        Finalize Payment to complete application
      </h1>

      <div className="max-w-6xl lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div className="max-w-2xl py-8 mx-auto">
          <form
            className="w-full max-w-2xl p-8 py-8 mx-auto rounded-lg shadow-md bg-blue-50"
            onSubmit={handleSubmit}
          >
            <div className="p-6 pb-6 border-b border-gray-200 rounded-md">
              {/* Credit Card Element */}
              <label className="block pb-8 text-sm font-medium text-gray-700">
                Enter Card Details
              </label>
              <div className="p-6 bg-white border border-gray-200 rounded-md">
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </div>
            </div>
            <div>
              <p className="mt-2 text-sm text-gray-500">
                You will be temporarily charged a $1 fee to verify your card.
              </p>
              <p className="mt-2 text-sm text-gray-500">
                This will be refunded within 3 business days.
              </p>
            </div>
            <div className="flex items-center justify-end mt-8 space-x-4">
              <button
                type="submit"
                className="px-4 py-2 font-semibold text-white rounded bg-primary-blue hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-200"
                disabled={isSubmitting}
              >
                Complete Application!
              </button>
            </div>
          </form>
        </div>
        <div>
          {/* Display Product Information */}
          <div className="w-full max-w-md p-8 m-8 mx-auto bg-white rounded-lg shadow-md">
            {/* Image */}
            <img
              src={image}
              alt={title} // Use product.title as the alt text
              className="object-cover mx-auto mb-6 rounded min-w-32 min-h-32"
            />

            {/* Product Info */}
            <div className="text-center">
              {console.log(product)}
              <h2 className="text-xl font-semibold">
                {product.title === "" ? "IPhone 14" : product.title}
              </h2>
              <p className="text-gray-500">
                Color: {color ? color.name : ""}
                <br />
                Storage: {storage}
                <br />
                Price: {product.monthUpFront
                  ? getUpfrontPaymentMessage()
                  : ""}{" "}
                ${selectedPrice} ({selectedRentalPeriod})
              </p>
              <p className="mt-8 text-gray-500">
                Payment will not be deducted until your Subscription application
                has been approved
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Customer Reviews */}
      <div className="">
        <div className="w-full p-8 m-8 mx-auto rounded-lg lg:grid lg:grid-cols-3">
          {customerReviews.map((review) => (
            <div className="p-4">
              <div className="p-4 bg-white border rounded-lg shadow-lg">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-lg font-semibold">{review.name}</h2>
                  <img
                    src="\stars5-trust-pilot.svg"
                    alt="5 stars"
                    className="w-1/2"
                  />
                </div>
                <p className="py-3 font-bold">{review.title}</p>
                <p className="text-gray-600">{review.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PaymentPage;

/**
 * const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // const result = await stripe.confirmCardPayment('{CLIENT_SECRET}', {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //     billing_details: {
    //       name: 'Jenny Rosen',
    //     },
    //   }
    // });

    // if (result.error) {
    //   // Show error to your customer (for example, insufficient funds)
    //   console.log(result.error.message);
    // } else {
    //   // The payment has been processed!
    //   if (result.paymentIntent.status === 'succeeded') {
    //     // Show a success message to your customer
    //     // There's a risk of the customer closing the window before callback
    //     // execution. Set up a webhook or plugin to listen for the
    //     // payment_intent.succeeded event that handles any business critical
    //     // post-payment actions.
    //     navigate("/thankYou");
    //   }
    // }
    navigate("/thankYou");


    
  }
 */
