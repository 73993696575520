import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { termsText } from '../../data/toc'


export default function TermsAndConditions({
  product,
  userId,
  stripeId,
  applicationId,
}) {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    console.log(product);
    console.log("t&c stripe id: " + stripeId);
  }, [product, stripeId]);

  const handleScroll = (e) => {
    const element = e.target;
    // Calculate the 90% scroll position
    const scrollPosition = element.scrollTop + element.clientHeight;
    const ninetyPercentScrollHeight = element.scrollHeight * 0.9;
  
    if (scrollPosition >= ninetyPercentScrollHeight) {
      setIsScrolledToBottom(true);
    }
  };

  return (
    <div className="mx-auto">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Terms & Conditions
          </h2>
          Please scroll to bottom of Terms & Conditions to continue
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6 sm:col-start-1">
              <div className="overflow-y-scroll h-96" onScroll={handleScroll}>
                <pre>{termsText}</pre>
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <Link
              to={"/payment"}
              state={{ product, userId, stripeId, applicationId }}
            >
              <button
                type="button"
                className={`rounded-md px-10 py-2 text-sm font-semibold text-white shadow-sm ${
                  isScrolledToBottom
                    ? "bg-primary-blue hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
                disabled={!isScrolledToBottom}
              >
                Next
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
