import React from "react";
import { termsText } from '../data/toc'

const PrivacyPolicy = () => {
  return (
    <div className="flex justify-center items-center py-16 bg-gray-100 px-4 text-balance">
        <div className="overflow-y-scroll h-96 bg-white">
          <pre className="px-8">{termsText}</pre>
        </div>
    </div>
  );
};

export default PrivacyPolicy;
