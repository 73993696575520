import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ index, link, product }) => {
  return (
    <div
      key={index}
      className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 px-4 py-2 mb-4"
    >
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <Link to={link} state={product} className="block">
          <img
            src={`data:image/png;base64,${product.image}`}
            alt={product.title}
            className="h-32 w-56 sm:h-40 sm:w-70 md:h-48 md:w-84 lg:h-56 lg:w-98 xl:h-64 xl:w-112 object-center object-scale-down mx-auto"
          />
          <div className="flex items-center justify-between px-4 pt-2">
            <h1 className="text-gray-800 text-md">
              From <br />
            </h1>
          </div>

          <div className="flex items-center justify-between px-4">
            <h1 className="text-gray-800 text-lg">
              <span className="font-bold text-2xl">
                ${product.minPrice == null ? "19.99" : product.minPrice}
              </span>{" "}
              Weekly
            </h1>
          </div>
          <div className="p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              {product.title}
            </h3>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
