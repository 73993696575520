import { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";
import { mapProducts } from "../functions/mapProduct";
import { useQuery } from "react-query";

async function fetchProducts(productId, searchQuery) {
  let url = API_BASE_URL + "/api/v1/product";
  console.log("searchQuery: " + searchQuery)
  if (productId) {
    url += `/${productId}`;
  } else if (searchQuery) {
    url += `${searchQuery}`;
  }
  console.log("url: " + url)
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Error fetching products: ${response.statusText}`);
  }
  return response.json();
}

function useProductData({ productId, searchQuery }) {
  const queryKey = ['products', productId, searchQuery];
  const { data, isLoading, error } = useQuery(queryKey, () => fetchProducts(productId, searchQuery), {
    onSuccess: (data) => {
      // Additional success handling if needed
    },
    onError: (error) => {
      console.error("Error fetching products:", error.message);
    }
  });

  const products = data ? mapProducts(data) : [];

  return { products, loading: isLoading, error };
}



export default useProductData;
