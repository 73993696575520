import React, { useState, useEffect, Suspense } from "react";
import Header, { HeaderSelection } from "../../components/Header";
import ProductList from "../../components/Product/ProductList";
import Footer from "../../components/Footer";
import useProductData from "../../hooks/useProductData";

function ProductListPage() {
  return (
    <>
      <div className="flex-col h-screen">
        <div className="mx-auto w-full mx-auto">
          <Header selectedHeader={HeaderSelection.Products}/>
          <Suspense
            fallback={
              <div className="w-full md:max-w-7xl mx-auto">
                <div className="mx-auto py-8">
                  <h2 className="text-4xl font-semibold text-gray-800 mb-4 text-center">
                    Most Popular
                  </h2>
                </div>
                <div className="flex flex-row justify-center space-x-4">
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                </div>
              </div>
            }
          >
            <ProductList
              title="Most Popular"
              searchQuery={{
                searchQuery: "?bestSeller=true",
              }}
            />
          </Suspense>
          <Suspense
            fallback={
              <>
                <div className="mx-auto py-8">
                  <h2 className="text-4xl font-semibold text-gray-800 mb-4 text-center">
                    All Products
                  </h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3">
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                  <ProductSkeleton />
                </div>
              </>
            }
          >
            <ProductList title="All Products" searchQuery={{}} />
          </Suspense>
        </div>
        <Footer />
      </div>
    </>
  );
}

export const ProductSkeleton = () => {
  return (
    <div className="w-full px-4 py-2 mb-4">
      <div className="animate-pulse bg-white shadow-md rounded-lg overflow-hidden">
        <div className="h-32 w-56 sm:h-40 sm:w-70 md:h-48 md:w-84 lg:h-56 lg:w-98 xl:h-64 xl:w-112 bg-gray-300 mx-auto" />
        <div className="p-4 space-y-4">
          <div className="h-4 bg-gray-300 rounded" />
          <div className="h-4 bg-gray-300 rounded" />
          <div className="h-4 bg-gray-300 rounded" />
        </div>
      </div>
    </div>
  );
};

export default ProductListPage;
