export const termsText = `
FlexiPhone Subscription Agreement

1. Introduction

This Subscription Agreement is a legally binding contract between you (you or your) and 
AtlasTech Solutions Pty Ltd ABN 38 668 691 441 (FlexiPhone). 
This Agreement governs your lease of a Device and any associated accessories 
(collectively, the Device) from FlexiPhone’s website.
By clicking "I Agree," you acknowledge that you have read, understood, and agree to be 
bound by the terms and conditions of this Agreement.

2. Definitions

In this Agreement:

• ACL means the Australian Consumer Law as set out in Schedule 2 of the 
Competition and Consumer Act 2010 (Cth).
• Agreement means this Subscription Agreement, including any schedules and 
amendments.
• Arrears means any amount owed by you under this Agreement that is overdue 
for payment.
• Cancellation Notice means the written notice required to terminate the 
Subscription Plan under Clause 15.1.
• Conditions of Return has the meaning set out in Clause 16.2.
• Cooling-O@ Period has the meaning set out in Clause 8.1.
• Device means the mobile phone, tablets, computers and any other devices and 
accessories leased to you under this Agreement.
• GST means Goods and Services Tax as defined in the A New Tax System (Goods 
and Services Tax) Act 1999 (Cth).
• Restocking Fee means the fee applicable upon certain events as detailed in this 
Agreement and found at www.flexiphone.com.au/restocking-fee.
• Subscription Fee means the periodic fee payable by you under your selected 
Subscription Plan.
• Subscription Plan means the plan selected by you for leasing the Device.
• Upfront Payment has the meaning set out in Clause 6.3.

3. Customer Representations and Warranties

You represent and warrant that:
• You are at least 18 years of age and have the legal capacity to enter into this 
Agreement.
• All information you provide to FlexiPhone is true, accurate, and complete.
• You will comply with all terms and conditions of this Agreement.

4. Acceptance of Terms

You accept this Agreement when you:
• Click to accept or agree to the Agreement where this option is made available to 
you by FlexiPhone; or
• Use the Device or any services associated with it.

5. Electronic Communications and Signatures

5.1 Consent to Electronic Communications
You consent to receive communications from FlexiPhone electronically, including via 
email and through FlexiPhone’s website. You agree that all agreements, notices, 
disclosures, and other communications provided electronically satisfy any legal 
requirement that such communications be in writing.
5.2 Electronic Signatures
By clicking "I Agree," or otherwise providing you written agreement to be bound by this 
Agreement, you provide your electronic signature, which is legally binding and 
equivalent to your handwritten signature.

6. Subscription Plans

6.1 Selection of Subscription Plan
You may select a Subscription Plan via FlexiPhone’s website, or via communication with 
Flexiphone, choosing from weekly, fortnightly, or monthly payment options.
6.2 Subscription Fee
You agree to pay the Subscription Fee specified in your selected Subscription Plan.
6.3 Upfront Payment
Before the Device is released to you, you must pay the following applicable Upfront 
Payment:
• For Weekly or Fortnightly Plans: Four weeks of Subscription Fees.
• For Monthly Plans: One month of Subscription Fees.
FlexiPhone will not authorise the delivery or release of the Device until the Upfront 
Payment is received.
6.4 Commencement Date
Your Subscription Plan commences on the date the Device is:
• Collected by you; or
• Delivered to you.

7. Direct Debit

7.1 Payment Authorisation
You authorise FlexiPhone to debit payments from your nominated credit or debit card(s) 
or bank account(s) via Stripe Payments Australia (Stripe) for any amounts payable 
under this Agreement. Payments may be debited from any accounts you enter into 
Stripe.
7.2 GST
If GST is payable on any amounts under this Agreement, you will pay that GST at the 
applicable rate. Changes in the GST rate will result in corresponding changes to the 
amounts payable.
7.3 Continued Obligation
Your obligation to pay fees under this Agreement continues even if the Device becomes 
unusable due to your actions or failure to act under the terms of this Agreement.
7.4 Impact on Credit Records
You understand that failure to pay amounts due under this Agreement may lead 
FlexiPhone to take actions that could adversely a]ect your credit records, which among 
other things, may potentially make it more di]icult or expensive for you to obtain credit 
in the future.

8. Cooling-Off Period

8.1 Duration
The Cooling-O] Period applies as follows:
• For Weekly or Fortnightly Plans: Four weeks.
• For Monthly Plans: One month.
8.2 Termination During Cooling-O@ Period
If you do not agree with the terms of this Agreement, or are not satisfied with 
FlexiPhone’s service, you may terminate your Subscription Plan and receive a refund of 
your Upfront Payment, provided that:
• You provide FlexiPhone with seven days' written notice prior to the end of the 
applicable Cooling-O] Period; and
• You return the Device to FlexiPhone prior to the end of the Cooling-O] Period.

9. Acceptable Use Policy

9.1 Permitted Use
You agree to use the Device only for its intended purpose and in accordance with this 
Agreement.
9.2 Prohibited Activities
You agree not to use the Device for any unlawful purposes, including:
• Engaging in illegal activities.
• Infringing upon the rights of others.
• Transmitting harmful or malicious content.
• Repair the Device without FlexiPhone’s prior written consent.
• Tampering with, altering, or modifying the software of any Device. This includes, 
but is not limited to, actions such as hacking, jailbreaking, rooting, bypassing 
security features, or installing unauthorised software.
Violation of this Acceptable Use Policy may result in immediate termination of your 
Subscription Plan and potential legal action.

10.Care of Device

10.1 Your Obligations
You must ensure the Device is not stolen or damaged and is maintained in good 
condition and working order, subject to fair wear and tear.
10.2 Usage Restrictions
The Device must:
• Be possessed and used only by you.
• Remain within Australia unless FlexiPhone agrees otherwise in writing.
• Be used in compliance with the manufacturer's instructions.
10.3 Screen Protector and Case
• Protective Measures: You must use a screen protector and case to protect the 
Device.
• Provision by FlexiPhone: FlexiPhone will provide a screen protector and case 
when possible.
• Replacement: If the provided accessories become damaged, you must obtain 
and use your own to protect the Device.

11.Lifetime Warranty

11.1 Coverage
While you are on a Subscription Plan, the Device is covered by a lifetime warranty 
against defects in quality and materials under normal use.
11.2 Limitations
• FlexiPhone does not guarantee uninterrupted or error-free operation of the 
Device.
• Device performance may be a]ected by system configuration, applications, 
data, and your operation of the system.
11.3 Warranty Claims
If you make a warranty claim, you must provide the Device to FlexiPhone for inspection. 
FlexiPhone may:
• Replace the Device with the same or a similar Device.
• Repair the Device and return it to you.
• Terminate your Subscription Plan and refund any payments attributable to the 
period after your warranty claim notice.

12.Damage

12.1 Liability for Damage
You are liable for any damage to the Device after supply and must immediately inform 
FlexiPhone.
12.2 Repair Costs
Subject to any manufacturer's warranty or consumer guarantees under the ACL, you 
must pay for any repair of the Device.
12.3 Charges for Damage
Depending on the extent of the damage, FlexiPhone may charge you:
• The reasonable costs of repairing the Device.
• The Restocking Fee, if repair is not feasible.
• Another amount agreed between the parties.

13.Data Responsibility

13.1 Data Protection
You are solely responsible for protecting the data stored on the Device.
13.2 No Liability for Data Loss
FlexiPhone is not responsible or liable for any loss of data on the Device.
13.3 Data Backup Recommendation
FlexiPhone recommends that you regularly back up your data.

14.Support Services

14.1 Customer Support
FlexiPhone o]ers customer support via email at support@flexiphone.com.au or by 
phone on (02) 6108 3900.
14.2 Limitations
Support services are provided during business hours and are subject to availability. 
FlexiPhone does not guarantee resolution times but will work diligently to support all its 
customers. 

15.Cancellation of Subscription Plan

15.1 Termination by Either Party
Either party may cancel the Subscription Plan by providing seven days' written notice 
prior to the next due payment (Cancellation Notice).
15.2 Immediate Termination by FlexiPhone
FlexiPhone may terminate the Subscription Plan immediately if it reasonably believes 
there is a risk that the Device will not be returned in good working order and free from 
damage.

16.Return of Device

16.1 Obligations Upon Cancellation
You must return the Device by the last date of your Subscription Plan as stated in your 
Cancellation Notice.
16.2 Conditions of Return
You must return the Device:
• Restored to factory settings.
• Unlocked and cleared of all personal data.
• Signed out of all personal accounts.
• In the original packaging or other packaging adequate protective material.
• In the same condition as when received, subject to fair wear and tear.
(the above collectively, being the Conditions of Return)
16.3 Responsibility and Liability
• You remain responsible for the Device until it is returned and the Conditions of 
Return are satisfied.
• If you send the Device back to FlexiPhone, you remain liable for any damage 
which occurs to the Device until FlexiPhone receives the Device. This includes 
any damages which occurs while in transit with a postal service or courier. 
• If posting the Device back to FlexiPhone, you must use tracked postage and 
provide the tracking number to FlexiPhone.
16.4 Failure to Return
If the Device is not received by the last date of your Subscription Plan as stated in your 
Cancellation Notice, fees under this Agreement will continue to be charged until the 
Device is returned.
16.5 Data Wiping
If the Device is not wiped of all data, FlexiPhone may wipe all data upon receipt.
16.6 Non-Compliance with Conditions of Return
If the Device fails to meet the Conditions of Return, FlexiPhone may charge you the 
Restocking Fee.

17.Late Fees

17.1 Overdue Payments
If you have overdue payments, you are liable to pay Late Fees as detailed in Schedule 1.

18.Restocking Fee

18.1 Termination Due to Arrears
If you are more than 28 days in arrears, FlexiPhone may terminate your Subscription 
Plan and charge you a Restocking Fee, in addition to any outstanding fees.
18.2 Cessation of Subscription Fees
Your obligation to pay Subscription Fees ceases on the day the Restocking Fee is 
charged.
18.3 Payment of Restocking Fee
You must pay the Restocking Fee within 14 days of being charged.

19.Financial Hardship Policy

19.1 Notification
If you are experiencing financial hardship a]ecting your ability to make payments, 
contact FlexiPhone immediately.
19.2 Assessment
You will need to provide relevant information for FlexiPhone to assess your situation 
confidentially. FlexiPhone may request further information from you to assess your 
situation.
19.3 Alternate Payment Plans
FlexiPhone will consider, but is not obligated to agree to, alternate payment plans. For 
the avoidance of doubt, such alternative payment plans will not reduce amounts owed 
to FlexiPhone.

20.Restriction of Functionality

20.1 Right to Restrict
If you are more than 14 days overdue in payments, FlexiPhone may remotely restrict the 
Device's functionality.
20.2 No Access to Personal Data
FlexiPhone will not access any data stored on your Device.

21.Possession and Ownership

21.1 No Transfer of Ownership
You will not own the Device, you are entitled only to possession and use of the Device.
21.2 Bailment
Your interest is solely as a bailee.
21.3 Restrictions on Rights
You agree not to:
• Transfer possession.
• Dispose of the Device.
• Encumber the Device.
• Create a lien over the Device.
• Assign rights or interests in the Device.

22.Purchase of Device

22.1 Request to Purchase
You may request to purchase the Device, however FlexiPhone is under no obligation to 
agree to your request.
22.2 Transfer of Title
If FlexiPhone agrees to sell the Device to you, ownership of the Device transfers to you 
upon receipt of the agreed purchase amount and any outstanding fees.
22.3 As-Is Sale
The Device is sold on an "as is" basis, reflecting its condition at the time of sale, 
including any disclosed faults or defects. We do not provide any additional warranties 
beyond those required by law. Nothing in this Agreement excludes, restricts, or modifies 
any rights or remedies you may have under the ACL.

23.Missing Device

23.1 Notification
You must notify FlexiPhone immediately if the Device is lost, destroyed, or stolen.
23.2 Liability
You are responsible for the Device until FlexiPhone receives it back.
23.3 Restocking Fee for Missing Device
FlexiPhone may charge you the Restocking Fee for a missing Device.

24.Indemnity

24.1 Your Liability
You are liable for, and indemnify FlexiPhone against, any reasonable fees, costs, and 
expenses incurred due to enforcing this Agreement, recovering late payments, or 
resulting from your breach of this Agreement.
24.2 Recovery of Costs
This includes third-party costs such as court fees, legal fees, and debt collection 
agency fees.

25.Liability

25.1 Compliance with ACL
Nothing in this Agreement limits your rights under the ACL.
25.2 Limitation of Liability
To the extent permitted by law, FlexiPhone's liability is limited to:
• Repairing the Device.
• Replacing the Device.
• Re-supplying the services.
25.3 Exclusion of Indirect and Consequential Loss
Neither party will be liable for indirect or consequential losses.

26.Compliance with Laws

26.1 Legal Compliance
You agree to comply with all applicable laws and regulations in relation to the Device.

27.Australian Consumer Law

27.1 Statutory Guarantees
FlexiPhone’s goods and services come with guarantees that cannot be excluded under 
the ACL.
27.2 No Exclusion of Rights
Nothing in this Agreement excludes, restricts, or modifies your rights under the ACL.

28.No Set-Off

28.1 Payment Obligations
You must pay all amounts due under this Agreement without set-o] or deduction.

29.Amendments to Agreement

29.1 Amendments by FlexiPhone
FlexiPhone may amend this Agreement by providing you with 42 days' prior written 
notice. If you do not agree to the changes, you may terminate your Subscription Plan 
without penalty before the amendments take e]ect.
29.2 Amendments by You
To propose an amendment, provide FlexiPhone with written notice detailing the 
changes. FlexiPhone is under no obligation to accept your proposed amendments.

30.Notices

30.1 Method of Delivery
Notices must be in writing and delivered personally, by registered mail, or by email.
30.2 Deemed Receipt
A notice is deemed received:
• On the date of delivery if delivered personally.
• Three business days after mailing if sent by registered mail.
• Upon receipt confirmation if sent by email.
30.3 Contact Information
• FlexiPhone
o Address: FlexiPhone, Level 5, 1 Moore St, Canberra ACT 2601
o Email: support@flexiphone.com.au

31.Privacy

31.1 Personal Information
Your personal information will be handled in accordance with FlexiPhone’s Privacy 
Policy at www.flexiphone.com.au/privacy-policy.

32.General Provisions

32.1 Relationship
This Agreement does not create any agency, partnership, or joint venture.
32.2 Waiver
A waiver is only e]ective if in writing. Failure to exercise a right is not a waiver.
32.3 Survival of Terms
Clauses relating to payment obligations, indemnity, liability, data responsibility, and any 
others intended to survive termination will remain in e]ect.
32.4 Severability
If any provision is invalid or unenforceable, it will be severed, and the remaining 
provisions will remain in e]ect.
32.5 Entire Agreement
This Agreement constitutes the entire agreement between the parties.
32.6 Governing Law
This Agreement is governed by the laws of the Australian Capital Territory. Parties 
submit to the exclusive jurisdiction of its courts.
32.7 Assignment
FlexiPhone may assign its rights and obligations without notice. You may not assign 
yours without FlexiPhone’s prior written consent.
32.8 Headings
Headings are for convenience and do not a]ect interpretation.
Schedule 1 – Late Fees
Application of Late Fees:
• First Four Weeks of Overdue Payments:
o Day 1 of Overdue Payment: A Late Fee of $5 is applied on the first day 
your payment is overdue.
o Day 8 of Overdue Payment: An additional Late Fee of $5 is applied at the 
beginning of the second week your payment remains overdue.
o Day 15 of Overdue Payment: An additional Late Fee of $5 is applied at 
the beginning of the third week your payment remains overdue.
o Day 22 of Overdue Payment: An additional Late Fee of $5 is applied at 
the beginning of the fourth week your payment remains overdue.
• After Four Weeks of Overdue Payments (From Day 29 Onwards):
o Day 29 of Overdue Payment and Every 7 Days Thereafter: A Late Fee of 
$20 is applied at the beginning of each subsequent week your payment 
remains overdue (e.g., on Days 29, 36, 43, etc.).
Please Note:
• Continued Subscription Fees: You are also liable for the continued payment of 
your Subscription Fees during the period of overdue payments.
• Accrual: The Late Fees are cumulative and will continue to accrue until all 
overdue amounts are paid in full or the Restocking Fee is charged to you in 
accordance with this Agreement.
• Reasonableness of Fees: Late Fees represent a genuine estimate of the costs 
incurred by FlexiPhone due to your late payment.
  `;