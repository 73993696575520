import Footer from "../../components/Footer";
import Header from "../../components/Header";

export const RefundPolicyPage = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center items-center py-16 bg-gray-100 px-4">
        <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow">
          <h1 className="text-xl font-bold mb-4">Refund Policy</h1>

          <p className="mb-2">
            Our goods and services come with guarantees that cannot be excluded
            under the Australian Consumer Law and our refund policy is subject
            to that Law/Act.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Cancellation of Hire/Subscription
          </h2>
          <p className="mb-2">
            As a customer, you may cancel your hire or subscription online or
            over the phone during specified hours. Conditions apply for
            cancellations close to the start date/time, including potential
            charges.
          </p>
          <p className="mb-2">
            Upon cancellation, FlexiPhone will refund any deposit taken for
            orders not yet received by the customer.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Cancellation of Subscription (Consumer)
          </h2>
          <p className="mb-2">
            Subscription cancellations can be made online or over the phone. The
            device must be returned within 24 hours following cancellation, with
            specific terms outlined in the Hire Agreement.
          </p>

          <h2 className="text-lg font-semibold my-4">Faulty Equipment</h2>
          <p className="mb-2">
            FlexiPhone is responsible for providing equipment free from fault.
            In case of faulty equipment, FlexiPhone will repair, replace, or
            refund the item.
          </p>
          <p className="mb-2">
            Conditions for repair or replacement vary based on the location of
            the hired equipment relative to the hiring branch.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Post-Hire Sales Items (ABN Holders)
          </h2>
          <p className="mb-2">
            Refunds for post-hire sales items are managed according to the
            FlexiPhone Sales and Returns Policy.
          </p>

          <p className="mb-2 italic">
            Disclaimer: FlexiPhone is a subsidiary trading under Australian
            Registered Company AtlasTech Solutions ABN: 38 668 691 441.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
