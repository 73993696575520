import React from "react";
import Upgrade from "../assets/UpgradeIco.png"
import Pickup from "../assets/Pickup.png"
import Signup from "../assets/SignUp.png"
import ChooseYourPhone from "../assets/ChooseYourPhone.png"


export default function HowItWorksSteps() {
  return (
    <div className="min-h-[104rem] md:min-h-0">
      {/* Title */}
      <h2 className="pb-16 text-2xl font-semibold text-center md:pb-16 text-blue-950">
        How It Works
      </h2>
      <div className="grid items-center justify-center max-w-3xl md:min-h-fit gap-y-80 md:gap-y-32">
        <div className="absolute inset-x-0 flex-col items-center justify-center hidden gap-y-2 md:flex">
          {/* repeat 50 times */}
          {Array.from({ length: 55 }).map((_, index) => (
            <div className="z-10 w-1 h-1 rounded-full bg-blue-950" />
          ))}
        </div>
        <div className="relative flex items-center justify-center min-w-[10rem] md:min-w-[32rem]">
          <div className="absolute z-50 p-4 text-xl text-center -bottom-72 md:-bottom-10 md:-left-40 w-72 text-blue-950">
            Choose your device

            <div className="text-sm text-blue-950">
              Choose a new device from our range. Pick the colour, storage & payment options that suit you.
            </div>
          </div>
          <div className="relative z-50 flex items-center justify-center w-20 h-20 text-3xl font-bold text-white rounded-full bg-blue-950">
            1
          </div>
          <img className="absolute w-48 pl-2 md:pl-0 object-fit md:-right-28 -bottom-48 md:-bottom-16" src={ChooseYourPhone} alt="logo" />
        </div>
        <div className="relative flex items-center justify-center">
          <div className="relative z-50 flex items-center justify-center w-20 h-20 text-3xl font-bold text-white rounded-full bg-blue-950">
            2
          </div>
          <div className="absolute z-50 p-4 text-xl text-center w-72 text-blue-950 md:-right-40 -bottom-72 md:-bottom-6">
            Apply in minutes

            <div className="text-sm text-blue-950">
              Sign up with our easy application process. Enter your details and get verified in real time.
            </div>
          </div>
          <img className="absolute w-48 pl-2 md:pl-0 object-fit md:-left-28 -bottom-48 md:-bottom-16" src={Signup} alt="logo" />

        </div>
        <div className="relative flex items-center justify-center">
          <div className="absolute z-50 p-4 text-xl text-center text-blue-950 md:-left-44 w-80 -bottom-72 md:-bottom-6">
            Delivered to your door

            <div className="text-sm text-blue-950">
              Free fast shipping so you can sit back and relax while we deliver the device straight to your door.
            </div>
          </div>
          <div className="relative z-50 flex items-center justify-center w-20 h-20 text-3xl font-bold text-white rounded-full bg-blue-950">
            3
          </div>
          <img className="absolute w-48 pl-2 md:pl-0 object-fit md:-right-28 -bottom-48 md:-bottom-16" src={Pickup} alt="logo" />

        </div>
        <div className="relative flex items-center justify-center">
          <div className="relative z-50 flex items-center justify-center w-20 h-20 text-3xl font-bold text-white rounded-full bg-blue-950">
            4
          </div>
          <div className="absolute z-50 p-4 text-xl text-center w-72 text-blue-950 md:-right-40 -bottom-72 md:-bottom-6">
            Upgrade anytime

            <div className="text-sm text-blue-950">
              Enjoy the device for as long or as little as you like. Switch, upgrade, or cancel anytime with no fee.
            </div>
          </div>
          <img className="absolute w-28 md:pl-0 object-fit md:-left-20 -bottom-40 md:-bottom-6" src={Upgrade} alt="logo" />
        </div>
      </div>
    </div>
  );
}
