import { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";

// Define the paths as constants
const HOME_PATH = "/";
const PRODUCTS_PATH = "/products";
const CONTACT_US_PATH = "/contactUs";
const ABOUT_US_PATH = "/aboutUs";
const LOGIN_PATH = "https://billing.stripe.com/p/login/3csdUcdo29e2gGQ144";

export const HeaderSelection = Object.freeze({
  Home: "Home",
  Products: "Products",
  Contact: "Contact",
  About_Us: "About_Us",
});

export default function Header({ selectedHeader }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Function to determine if a header is selected
  const isSelected = (header) => selectedHeader === header;

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to={HOME_PATH} className="-m-1.5 p-1.5">
            <img className="w-1/2 max-w-lg" src="\flexiphone_full_logo.png" alt="logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-24">
          <Link
            to={PRODUCTS_PATH}
            className={`text-md grid items-center font-semibold leading-6 text-navy-blue ${
              isSelected(HeaderSelection.Products)
                ? "border-b-2 border-blue-500"
                : ""
            }`}
          >
            Products
          </Link>
          <Link
            to={CONTACT_US_PATH}
            className={`text-md grid items-center font-semibold leading-6 text-navy-blue ${
              isSelected(HeaderSelection.Contact)
                ? "border-b-2 border-blue-500"
                : ""
            }`}
          >
            Contact Us
          </Link>
          <Link
            to={ABOUT_US_PATH}
            className={`text-md grid items-center font-semibold leading-6 text-navy-blue ${
              isSelected(HeaderSelection.About_Us)
                ? "border-b-2 border-blue-500"
                : ""
            }`}
          >
            About Us
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            to={LOGIN_PATH}
            className="text-md font-semibold leading-6 text-navy-blue"
          >
            Manage Account <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={HOME_PATH} className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-16 w-auto" src="\flexiphone_full_logo.png" alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to={PRODUCTS_PATH}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-navy-blue hover:bg-gray-50 ${
                    isSelected(HeaderSelection.Products)
                      ? "border-b-2 border-blue-500"
                      : ""
                  }`}
                >
                  Products
                </Link>
                <Link
                  to={CONTACT_US_PATH}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-navy-blue hover:bg-gray-50 ${
                    isSelected(HeaderSelection.Contact)
                      ? "border-b-2 border-blue-500"
                      : ""
                  }`}
                >
                  Contact Us
                </Link>
                <Link
                  to={ABOUT_US_PATH}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-navy-blue hover:bg-gray-50 ${
                    isSelected(HeaderSelection.About_Us)
                      ? "border-b-2 border-blue-500"
                      : ""
                  }`}
                >
                  About Us
                </Link>
              </div>
              <div className="py-6">
                <Link
                  to={LOGIN_PATH}
                  target="_blank"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-navy-blue hover:bg-gray-50"
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
