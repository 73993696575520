import React from 'react';

const PageNumbers = ({ totalPages, currentPage }) => {
  return (
    <div className="flex w-full space-x-16 justify-center  pb-8 text-2xl">
      {Array.from({ length: totalPages }, (_, index) => (
        <div
        key={index}
        className={`w-10 h-10 md:w-12 md:h-12 flex items-center justify-center rounded-md border border-gray-300 text-gray-700 ${
          currentPage === index + 1
            ? 'bg-blue-500 text-white scale-125' // Enlarged and highlighted for the current page
            : ''
        }`}
        title={`Page ${index + 1}`}
      >
        {index + 1}
      </div>
      ))}
    </div>
  );
};

export default PageNumbers;
