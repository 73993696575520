/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const ImageView = ({ image, link, button, className }) => {
  
  function renderImage() {
    if (link) {
      return (
        <Link to={link}> {/* Wrap content with Link */}
        <div className={"relative h-96" + className}>
          {/* Image */}
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={image}
            alt="Image View"
            className="object-cover w-full h-full mx-auto "
          />
  
          {/* View All Button */}
          {button && button}
        </div>
      </Link>
      )
    } else {
      return (<div className={"relative h-96" + className}>
          {/* Image */}
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={image}
            alt="Image View"
            className="object-cover w-full h-full mx-auto "
          />
  
          {/* View All Button */}
          {button && button}
        </div>
      )
    }
  }
  
  return (
     renderImage()
    );
  };

export default ImageView;
