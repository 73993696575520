import React from 'react'
import Header, { HeaderSelection } from '../../components/Header';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';

function ContactUsPage() {
    return (
      <div>
        <Header selectedHeader={HeaderSelection.Contact}/>
        <ContactUs />
        <Footer />
      </div>
    );
  }
  
  export default ContactUsPage;
  