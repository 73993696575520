import { useState } from "react";
import { Transition } from "@headlessui/react";

import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "What are my options at the end of my Subscription?",
    answer:
      "You have lots of options! When your subscription period wraps up, you can either extend your current subscription, send back your device to us, or swap it for a different model. We're all about giving you the freedom to choose what's best for you.",
  },
  {
    question:
      "What happens if I do nothing before the end of my Subscription Period?",
    answer:
      "You've got some time! If your subscription period is coming to an end and you haven't taken any action, we'll make sure to send you a reminder. You'll have 24 hours to decide on your next steps before any fees are applied.",
  },
  {
    question:
      "What is FlexiPhone's policy if I decide the service isn't right for me?",
    answer:
      "We stand behind our service with a 28-day money back guarantee. If for any reason you're not satisfied or find the device isn't suitable for you, simply return it within 28 days to receive a full refund, hassle-free.",
  },
  {
    question:
      "What happens if my Subscription Device is Lost, Stolen or Unrecoverable?",
    answer:
      "Accidents can happen! Should your subscription device become lost, stolen, or irrecoverable, we're here to assist in any way we can to help find it. However, if it turns out that the device can't be recovered, you would be responsible for the replacement cost of the device.",
  },
  {
    question: "What happens if I don't like the Subscription Device I have?",
    answer:
      "Not to worry! If the device you've chosen isn't quite what you were hoping for, you have the flexibility to swap it for another available model. Plus, any remaining subscription period will be credited to your new plan.",
  },
  {
    question: "I want to cancel my Subscription Agreement, what should I do?",
    answer:
      "We're all about being straightforward! While we'll be sad to see you leave, we're committed to making your departure as effortless as signup. If you decide it's time to move on, simply reach out and return the device before your renewal date.",
  },
  {
    question: "Can I receive any free Subscription Periods?",
    answer:
      "Absolutely! With our trade-in program, simply hand over your old device, and we'll provide you with equivalent value in free subscription weeks. Imagine kick-starting your new subscription without spending a dime, all by letting us give your beloved device a second life!",
  },
  {
    question:
      "Can I pause my subscription if I'm traveling or not using my device?",
    answer:
      "Taking a break? No problem. FlexiPhone offers the flexibility to pause your subscription if you're traveling or won't need your device for a while. Just return your device to keep your plan on hold and resume whenever you're ready.",
  },
  {
    question: "Are there any hidden costs with FlexiPhone subscriptions?",
    answer:
      "Transparency is our promise! With FlexiPhone, what you see is what you get - no hidden fees, no surprises. We're committed to clear, straightforward communication about your subscription costs.",
  },
  {
    question:
      "How does FlexiPhone ensure the quality of its subscription devices?",
    answer:
      "Quality is our guarantee! Every FlexiPhone device undergoes a rigorous quality check to ensure it meets our high standards. Enjoy peace of mind knowing you're getting a premium device, every time.",
  },
  {
    question:
      "What kind of support can I expect if I encounter issues with my device?",
    answer:
      "We're always here for you! Should you face any issues with your device, our dedicated support team is on standby to assist you. Contact us at (02) 6108 3900 or support@flexiphone.com.au for quick and efficient help anytime.",
  },
];

function FAQ() {
  return (
    <div className="bg-white">
      <div className="px-6 pb-12 mx-auto max-w-7xl sm:pb-12 lg:px-8 lg:pb-12">
        <div className="max-w-4xl mx-auto divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="flex items-center ml-6 h-7">
                          {open ? (
                            <MinusSmallIcon
                              className="w-6 h-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="w-6 h-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="pr-12 mt-2">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
