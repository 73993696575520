export default function ComparisonTable() {
  // Define the features and their availability for FlexiPhone and other options
  const features = [
    {
      name: "Switch phones when you like?",
      flexiPhone: "Switch phones any time.",
      other: "Locked in for months",
    },
    {
      name: "Lifetime Warranty and support included?",
      flexiPhone: "Lifetime Warranty and support included.",
      other: "Pay for your own. $$$",
      last: false,
    },
    {
      name: "No deposit, signup or establishment fees?",
      flexiPhone: "No deposit, signup or establishment fees",
      other: "Signup and shipping fees",
      last: false,
    },
    {
      name: "No time wasted buying or selling phones?",
      flexiPhone: "Trade in for credit with ease.",
      other: "Sell your own phone yourself",
      last: false,
    },
    {
      name: "No lock-in contact or long-term commitment?",
      flexiPhone: "No lock-in contact or long-term commitment.",
      other: "Locked in and long term contract",
      last: true,
    },
  ];

  return (
    <div className="py-8 mx-auto sm:px-2 md:px-8">
      <div className="py-8 mx-auto">
        <h2 className="text-2xl font-semibold text-center text-blue-900">
          Why Subscribe?
        </h2>
      </div>
      <div className="p-2 rounded-lg">
        <table className="min-w-full mx-auto text-center border-none rounded-lg">
          <thead className="">
            <tr>
              <th></th>
              <th className="h-5 bg-blue-900 rounded-t-md"></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-white bg-blue-900 rounded-md">
              <td className="px-4 py-2 text-xl font-semibold rounded-tl-md">Features</td>
              <td className="px-4 py-2 text-xl font-semibold bg-blue-900 border border-blue-900">FlexiPhone</td>
              <td className="px-4 py-2 text-xl font-semibold rounded-tr-lg">Others</td>
            </tr>
            {features.map((feature, index) => (
              <tr
                key={index}
                className={`${index % 2 === 0 ? "bg-gray-200" : "bg-gray-300"}`}
              >
                <td
                  className={`px-4 py-2 text-md md:text-lg lg:text-xl font-medium text-left text-blue-950 ${
                    feature.last === true ? " rounded-bl-md" : ""
                  }`}
                >
                  {feature.name}{" "}
                </td>
                <td
                  className={`px-4 py-2 mx-2 text-md md:text-lg font-medium bg-blue-900 border-t border-b border-blue-900`}
                >
                  <div className="px-2 py-1 text-green-500 rounded-md lg:m-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-8 h-8 mx-auto"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  className={`py-2 mx-2 ${
                    feature.last === true ? " rounded-br-md" : ""
                  }`}
                >
                  <div className="px-2 py-1 text-gray-800 rounded-md lg:m-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 mx-auto"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td className="px-4 py-2 font-medium text-md md:text-lg text-blue-950 rounded-bl-md"></td>
              <td className="h-5 bg-blue-900 rounded-b-md"></td>
              <td className="py-2 mx-2 rounded-br-md"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
