import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex justify-center items-center py-16 bg-gray-100 px-4">
      <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow">
        <h1 className="text-xl font-bold mb-4">Privacy Policy</h1>

        <p className="mb-2">
          At FlexiPhone, we recognize the importance of protecting your personal
          information and are committed to processing it responsibly and in
          compliance with applicable data protection laws in all countries in
          which FlexiPhone operates.
        </p>

        <h2 className="text-lg font-semibold my-4">
          Collection of Personal Information
        </h2>
        <p className="mb-2">
          We collect personal information such as your name, contact details,
          and other necessary data through various means, including hire
          agreements, account application forms, and interactions with our
          website.
        </p>

        <h2 className="text-lg font-semibold my-4">
          Use of Personal Information
        </h2>
        <p className="mb-2">
          Your personal information is used for business purposes, such as
          responding to your enquiries, providing products and services, and
          informing you of new products and services that may interest you.
        </p>

        <h2 className="text-lg font-semibold my-4">Opting Out</h2>
        <p className="mb-2">
          You have the option to opt out of receiving promotional materials from
          us at any time.
        </p>

        <h2 className="text-lg font-semibold my-4">
          Disclosure to Third Parties
        </h2>
        <p className="mb-2">
          We do not disclose your personal information to unrelated parties
          outside our organisation, except as required by law or with your
          consent.
        </p>

        <h2 className="text-lg font-semibold my-4">
          Protecting Personal Information
        </h2>
        <p className="mb-2">
          We take all reasonable steps to protect your personal information from
          misuse, loss, unauthorized access, modification, or disclosure.
        </p>

        <h2 className="text-lg font-semibold my-4">
          Accessing and Updating Personal Information
        </h2>
        <p className="mb-2">
          You have the right to access and update your personal information that
          we hold.
        </p>

        <h2 className="text-lg font-semibold my-4">Contacting Us</h2>
        <p className="mb-2">
          For more information about our privacy policy or to access/update your
          personal information, please contact us.
        </p>

        <p className="mb-2 italic">Last updated: 11/12/2023</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
