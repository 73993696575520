import { PhotoIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export default function FileUpload({
  title,
  description,
  typeAndLimit,
  fileUploadId,
  fileSetReference,
  fileLimit = 10,
}) {
  const [uploadedFileName, setUploadedFileName] = useState(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    setUploadedFileName(file.name);
    if (file.size > fileLimit * 1024 * 1024) {
      alert(
        `The file ${file.name} size is too large. The maximum allowed file size is ${fileLimit}MB.`
      );
      setUploadedFileName(null);
      fileSetReference(null);
      return;
    }
    fileSetReference(file);
  };

  return (
    <div className="">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title}
      </label>
      <div
        className={`mt-2 flex justify-center rounded-lg border ${
          uploadedFileName
            ? "border-green-500"
            : "border-dashed border-gray-900/25"
        } px-6 py-10`}
      >
        <div className="text-center">
        {uploadedFileName ? (
            <PhotoIcon className="mx-auto h-12 w-12 text-green-500" />
          ) : (
            <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
          )}
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor={fileUploadId}
              className="relative cursor-pointer rounded-md bg-white font-semibold text-primary-blue focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-blue focus-within:ring-offset-2 hover:text-indigo-500"
            >
              <span>{description}</span>
              <input
                id={fileUploadId}
                name={fileUploadId}
                type="file"
                className="sr-only"
                onChange={handleChange}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">{typeAndLimit}</p>
        </div>
      </div>
      {uploadedFileName && (
        <div className="mt-2 flex items-center text-sm font-medium leading-6 text-green-600">
          <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
          Uploaded file: {uploadedFileName}
        </div>
      )}
    </div>
  );
}
