import Footer from "../../components/Footer";
import Header from "../../components/Header";

export const SalesAndReturnPage = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center items-center py-16 bg-gray-100 px-4">
        <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow">
          <h1 className="text-xl font-bold mb-4">Sales and Returns Policy</h1>

          <p className="mb-2">
            Thank you for shopping at FlexiPhone. The following terms and
            conditions apply to transactions at a FlexiPhone Store, in
            accordance with our Privacy Policy.
          </p>

          <h2 className="text-lg font-semibold my-4">Standard Return Policy</h2>
          <p className="mb-2">
            We believe you will be thrilled with the products you purchase from
            FlexiPhone. However, if you need to return a product, you can do so
            within 14 days of receipt, provided the product is undamaged and
            includes all accessories and packaging. Note that refunds will only
            be issued to an Australian bank account in the payor’s name.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Additional FlexiPhone Return Terms
          </h2>
          <p className="mb-2">
            Unauthorized modifications to the software on an iPhone may
            invalidate your warranty and the ability to return the device.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Pricing and Price Reductions/Corrections
          </h2>
          <p className="mb-2">
            Prices for products displayed at/on the FlexiPhone website are
            subject to change. Pricing errors may be corrected at any time.
          </p>

          <h2 className="text-lg font-semibold my-4">Order Acceptance</h2>
          <p className="mb-2">
            FlexiPhone reserves the right to refuse or cancel any order for any
            reason. If your order is cancelled after billing, you will be
            refunded the billed amount.
          </p>

          <h2 className="text-lg font-semibold my-4">Shipping & Delivery</h2>
          <p className="mb-2">
            Shipping and delivery charges are at the customer's expense.
            FlexiPhone is not liable for late deliveries but will work with you
            to ensure smooth delivery.
          </p>

          <h2 className="text-lg font-semibold my-4">
            In-Store Pickup and Return
          </h2>
          <p className="mb-2">
            In-store pickup is available for certain products and payment
            methods. A government-issued photo ID and order number are required
            for pickup.
          </p>

          <h2 className="text-lg font-semibold my-4">Pickup Contact</h2>
          <p className="mb-2">
            You may designate a third party to pick up your order, subject to
            certain product and payment method restrictions.
          </p>

          <h2 className="text-lg font-semibold my-4">
            Product Availability and Limitations
          </h2>
          <p className="mb-2">
            FlexiPhone may limit the number of products available for purchase
            and reserves the right to change these quantities at any time.
          </p>

          <h2 className="text-lg font-semibold my-4">Data Protection</h2>
          <p className="mb-2">
            By placing your order, you agree that we may store, process, and use
            data collected from your order as outlined in our Privacy Policy.
          </p>

          <p className="mb-2 italic">Last updated: 11/12/2023</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
