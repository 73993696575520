import { useState } from "react";

const useFormUpload = (endpoint) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const uploadData = async (formData) => {
      setIsLoading(true);
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
          setResponse(data);
        } else {
          throw new Error("Error uploading data"); // Throw an error when response is not ok
        }
      } catch (error) {
        console.log(error);
        setError("Error uploading data");
      } finally {
        setIsLoading(false);
      }
    };
  
    return { response, error, isLoading, uploadData };
  };
  
  export default useFormUpload;
  
