import React, { Suspense, useEffect } from "react";
import Header from "../../components/Header";
import ProductDisplay from "../../components/Product/ProductDisplay";
import Footer from "../../components/Footer";

import ProductList from "../../components/Product/ProductList";
import { useParams } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";

import HowItWorks from "../../components/HowItWorks";
import { STEPS } from "../../CopyWriting";
import useProductData from "../../hooks/useProductData";
import FAQ from "../../components/FAQ";
import Skeleton from "react-loading-skeleton";
import { ProductSkeleton } from "../ProductListPage/ProductListPage";
import HowItWorksSteps from "../../components/HowItWorksSteps";
import SwapAnimationComponent from "../../components/SwapAnimationComponent";
import WhatsIncluded from "../../components/WhatsIncluded";

function ProductPage() {
  const { productId } = useParams();

  return (
    <div>
      <Header />
      <div>
        <React.Suspense fallback={<ProductDisplaySkeleton />}>
          <ProductDisplay productId={productId} link="/apply" />
        </React.Suspense>
      </div>

      {/* How It Works */}
      <div className="flex flex-col items-center mx-auto overflow-x-clip">
        <HowItWorksSteps />
      </div>
      <div className="max-w-4xl p-8 mx-auto my-16 bg-blue-100 rounded-lg shadow-lg">
        <h2 className="pb-16 text-2xl font-semibold text-center md:py-8 text-blue-950">
          Whats Included
        </h2>
        <WhatsIncluded />
      </div>
      <div className="flex flex-col items-center py-16 mx-auto my-16 bg-gray-100 overflow-x-clip">
        <SwapAnimationComponent />
      </div>
      <Suspense
        fallback={
          <div className="w-full mx-auto md:max-w-7xl">
            <div className="py-8 mx-auto">
              <h2 className="mb-4 text-4xl font-semibold text-center text-gray-800">
                Most Popular
              </h2>
            </div>
            <div className="flex flex-row justify-center space-x-4">
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
            </div>
          </div>
        }
      >
        <ProductList
          title={"You May Also Like"}
          searchQuery={{ searchQuery: "?bestSeller=true" }}
        />
      </Suspense>

      <FAQ />
      <Footer className="bg-neutral-200" />
    </div>
  );
}

export default ProductPage;

const ProductDisplaySkeleton = () => {
  return (
    <div>
      <section className="py-12 sm:py-16">
        <div className="container px-4 mx-auto">
          <div className="grid grid-cols-1 gap-12 mt-8 lg:grid-cols-5 lg:gap-16 lg:mt-12">
            {/* Image Carousel Skeleton */}
            <div className="lg:col-span-3">
              <Skeleton height={500} />
              <div className="flex justify-center mt-4">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    height={50}
                    width={50}
                    className="mx-2"
                  />
                ))}
              </div>
            </div>

            {/* Product Details Skeleton */}
            <div className="lg:col-span-2">
              <Skeleton height={30} width={200} />
              <Skeleton height={20} width={150} className="my-2" />
              <Skeleton height={40} width={300} className="my-4" />
              <Skeleton height={40} className="my-2" />
              <Skeleton height={40} className="my-2" />
              <Skeleton height={50} className="mt-4" />
            </div>
          </div>
        </div>
      </section>

      {/* Box Content and Description Skeleton */}
      <div className="mx-auto py-8 max-w-[100rem]">
        <Skeleton height={30} width={200} className="mb-4" />
        <Skeleton height={500} className="mx-auto" />
        <Skeleton height={30} width={200} className="mx-auto my-8" />
        <Skeleton count={5} className="mx-auto" />
      </div>
    </div>
  );
};
