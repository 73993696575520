import React, { Suspense, useEffect, useState } from "react";
import Header, { HeaderSelection } from "../../components/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import iphone15 from "../../assets/iphone-15.jpg";
import iphone13 from "../../assets/iphone-13.jpg";
import ImageSlider from "../../components/ImageSlider";
import ProductList from "../../components/Product/ProductList";
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import HowItWorks from "../../components/HowItWorks";
import { STEPS } from "../../CopyWriting";
import HeaderBanner from "../../components/HeaderBanner";
import { ProductSkeleton } from "../ProductListPage/ProductListPage";
import ComparisonTable from "./ComparisonTable";
import { customerReviews } from "../ApplyProcess/PaymentPage";
import { Carousel } from "react-responsive-carousel";
import iphone12 from "../../assets/iphone 12.avif";
import SwapAnimationComponent from "../../components/SwapAnimationComponent";
import HowItWorksSteps from "../../components/HowItWorksSteps";
import WhatsIncluded from "../../components/WhatsIncluded";
import DamageCoverIconWhite from "../../assets/DamageCoverIconWhite.png";
import WarrantyIconWhite from "../../assets/WarrantyIconWhite.png";
import SupportIconWhite from "../../assets/SupportIconWhite.png";
import {
  CheckCircle,
  CheckCircle2,
  CheckCircleIcon,
  CheckIcon,
} from "lucide-react";

const HomePage = () => {
  return (
    <div className="flex flex-col h-screen mx-auto overflow-x-clip">
      <Header />
      {/* Small banner */}
      <div className="relative w-full bg-blue-950">
        <div className="flex justify-center px-2 py-2 mx-auto text-xs flex-cols gap-x-2 md:text-sm gap-y-4 max-w-7xl bg-blue-950">
          <div className="flex items-center justify-center w-full mx-auto">
            <CheckCircleIcon
              size={24}
              className="w-4 h-4 mx-1 text-white md:mx-2"
            />
            <h1 className="font-semibold text-white whitespace-nowrap">
              No deposit
            </h1>
          </div>
          <div className="flex items-center justify-center w-full mx-auto">
            <CheckCircleIcon
              size={24}
              className="w-4 h-4 mx-1 text-white md:mx-2"
            />
            <h1 className="font-semibold text-white whitespace-nowrap">
              No Lock in Contracts
            </h1>
          </div>
          <div className="flex items-center justify-center w-full mx-auto">
            <CheckCircle
              size={24}
              className="w-4 h-4 mx-1 text-white md:mx-2"
            />

            <h1 className="font-semibold text-white whitespace-nowrap">
              Cancel Anytime
            </h1>
          </div>
        </div>
      </div>
      <div>
        <HeaderBanner />
      </div>
      {/* Customer Reviews */}
      <div className="max-w-4xl mx-auto ">
        <div className="p-8 pt-16 text-2xl font-semibold text-center">
          Why Customers Love FlexiPhone.
        </div>
        <div className="grid mx-auto grid:cols-1 lg:grid-cols-2 overflow-clip">
          <div className="items-center max-w-lg p-4 mx-auto">
            <div className="max-w-sm pb-4 mx-auto md:max-w-xl lg:max-w-xl">
              <div className="p-4 bg-white border rounded-lg shadow-lg h-72 overflow-clip">
                {/* Trust Pilot Review Total */}
                <div className="text-xl text-center">Trust Pilot</div>
                <div className="flex items-center justify-center">
                  <img
                    src="\stars5-trust-pilot.svg"
                    alt="Trust Pilot"
                    className="w-64 h-24"
                  />
                  <div className="ml-4 text-xl font-bold">4.5</div>
                </div>
                {/* number of reviews */}
                <div className="text-lg text-center">
                  Our customers love our service! FlexiPhone is celebrated for
                  making technology more accessible through our flexible
                  subscription plans.
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-sm pb-4 mx-auto md:max-w-md lg:max-w-md">
            <Carousel
              showArrows={true}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showIndicators={false}
              autoPlay={true}
              interval={4000}
            >
              {customerReviews.map((review) => (
                <div className="items-center max-w-lg p-4 mx-auto">
                  <div className="p-4 bg-white border rounded-lg shadow-lg h-72 overflow-clip">
                    <div className="grid items-center grid-cols-2 mb-2 overflow-hidden">
                      <h2 className="w-40 p-4 text-lg font-semibold text-left">
                        {review.name}
                      </h2>
                      <img
                        src="\stars5-trust-pilot.svg"
                        alt="5 stars"
                        className="w-20 h-10"
                      />
                    </div>
                    <p className="p-4 py-3 font-bold text-left">
                      {review.title}
                    </p>
                    <p className="p-4 text-left text-gray-600">
                      {review.description}
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      {/* How It Works */}
      <div className="max-w-4xl px-8 pb-16 mx-auto">
        <HowItWorksSteps />
      </div>
      <div className="max-w-4xl p-8 mx-auto my-4 bg-blue-100 rounded-lg shadow-lg">
        <h2 className="pb-16 text-2xl font-semibold text-center md:py-8 text-blue-950">
          Whats Included
        </h2>
        <WhatsIncluded />
      </div>
      {/* Payment Steps, 4 weeks up front then pay on your schedule */}
      <div className="mt-12 bg-gray-100">
        <SwapAnimationComponent />
      </div>

      <div className="grid items-center max-w-4xl grid-cols-1 gap-4 p-6 mx-auto bg-white md:pl-24 md:grid-cols-2">
        <div className="order-2 md:order-1">
          <h1 className="mb-2 text-4xl font-bold text-center md:text-left">
            Subscribe to the iPhone 12 Now
          </h1>
          <p className="max-w-xl mb-4 text-sm text-center text-gray-600 md:text-left">
            iPhone 12 subscriptions starting from only $12 per week. Includes
            device, optional damage cover, unlimited warranty, and full
            support. Cancel, pause, or upgrade at any time. No lock in
            contracts, or sneaky fees.
          </p>
          <div className="flex justify-center md:justify-start">
            <a
              href="/Apple%20iPhone%2012/e7cebe3a-4d6b-4bb2-96dd-5c720b56d46f"
              className="px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-600"
            >
              Get Yours Now
            </a>
          </div>
        </div>
        <div className="relative order-1 md:order-2">
          <img src={iphone12} alt="iPhone 12" className="w-full h-auto p-16" />
          <div
            className="absolute right-0 flex flex-col items-center justify-center p-4 font-semibold text-blue-800 bg-blue-100 rounded-full bottom-8 md:right-16"
            style={{ aspectRatio: "1 / 1" }}
          >
            <span className="text-xl sm:text-2xl md:text-3xl">$12</span>
            <span className="text-xs sm:text-sm md:text-xl">per week</span>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray-100">
        <div className="max-w-4xl mx-auto my-0 md:my-4">
          <ComparisonTable />
        </div>
      </div>

      <Suspense
        fallback={
          <div className="w-full mx-auto md:max-w-7xl">
            <div className="py-8 mx-auto">
              <h2 className="mb-4 text-2xl font-semibold text-center text-gray-800">
                Most Popular
              </h2>
            </div>
            <div className="flex flex-row justify-center space-x-4">
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
            </div>
          </div>
        }
      >
        <ProductList
          title="Most Popular"
          searchQuery={{ searchQuery: "?bestSeller=true" }}
        />
      </Suspense>
      {/* <CTA
        title1="After a specific model?"
        description="A wide range of iphones to choose from with amazing rates."
        link="/products"
        linkText="See more here"
        bgColour="bg-teal-500"
        headingColor="text-blue-900"
        bodyColor="text-gray-800"
      /> */}

      <FAQ />

      <Footer className="bg-neutral-200" />
    </div>
  );
};

export default HomePage;
