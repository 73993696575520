import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, useSearchParams } from "react-router-dom";
import ColorCircles from "./ColorCircles";
import posthog from "posthog-js";
import { API_BASE_URL } from "../../config";
import mapProduct from "../../functions/mapProduct";
import { useQuery } from "react-query";
import ImageView from "../ImageView";
import Slider from "react-slick";

//https://componentland.com/component/product-page-2
const ProductDisplay = ({ productId, link }) => {
  //const
  const disabledDropdownClass = "bg-gray-200 text-gray-500 cursor-not-allowed";

  const [searchParams, setSearchParams] = useSearchParams();

  const fetchProduct = async () => {
    const response = await fetch(API_BASE_URL + `/api/v1/product/${productId}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return mapProduct(data);
  };

  const { data: product } = useQuery(["product", productId], fetchProduct);

  // Initialize state from URL or default values
  const initialStorage =
    searchParams.get("storage") ||
    (() => {
      // Check for preferred storage options in order
      const preferredOptions = ["64gb", "128gb", "256gb", "512gb"];
      for (let option of preferredOptions) {
        if (product.storage.includes(option)) {
          return option;
        }
      }

      // If none of the preferred options are available, sort alphabetically and pick the first one
      return product.storage.slice().sort()[0];
    })();

  //sort the product.rentalPeriods
  product.rentalPeriods.sort((a, b) => {
    if (a === "Weekly") return -1;
    if (b === "Weekly") return 1;
    if (a === "Fortnightly") return -1;
    if (b === "Fortnightly") return 1;
    if (a === "Monthly") return -1;
    if (b === "Monthly") return 1;
    if (a === "Quarterly") return -1;
    if (b === "Quarterly") return 1;
    return 0;
  });
  
  const initialRentalPeriod =
    searchParams.get("rentalPeriod") || product.rentalPeriods[0];
  const initialColor = searchParams.get("color") || product.colors[0].id;

  //initializing useState variables
  const [selectedStorage, setSelectedStorage] = useState(initialStorage);
  const [selectedRentalPeriod, setSelectedRentalPeriod] =
    useState(initialRentalPeriod);
  const [selectedColor, setSelectedColor] = useState(initialColor);
  const [selectedPrice, setSelectedPrice] = useState(product.minPrice);
  const [selectedVariation, setSelectedVariation] = useState(
    product.variations[0] //set a default product variations to avoid errors, is immediately overridden with the correct one in useffect
  );

  //create lock variable to lock the product display
  const isLocked = searchParams.get("lock") === "true";

  const handleRentalPeriodChange = (e) => {
    setSelectedRentalPeriod(e.target.value);
  };

  const handleStorageChange = (e) => {
    setSelectedStorage(e.target.value);
    setSelectedVariation(
      product.variations.find(
        (variation) => variation.storage === e.target.value
      )
    );
  };

  function getSelectedProduct() {
    posthog.capture("apply_clicked", {
      // You can add additional properties here if needed
      label: "Apply Button",
      timestamp: new Date().toISOString(),
    });
    return {
      product: {
        id: product.id,
        title: product.name ?? "",
        selectedColor: selectedColor,
        storage: selectedStorage,
        variation: selectedVariation, //variation object
        selectedStorage: selectedStorage, //storage selected
        selectedRentalPeriod: selectedRentalPeriod, //selected Subscription period
        selectedPrice: selectedPrice, //cannot be used for pricing
        color: product.colors.find((color) => color.id === selectedColor),
        monthUpFront: product.monthUpFront,
      },
    };
  }

  const getPriceFromVariation = (variation) => {
    switch (selectedRentalPeriod) {
      case "Daily":
        return variation.pricePerDay;
      case "Weekly":
        return variation.pricePerWeek;
      case "Fortnightly":
        return variation.pricePerFortnight;
      case "Monthly":
        return variation.pricePerMonth;
      case "Quarterly":
        return variation.pricePerQuarter;
      default:
        return variation.pricePerWeek;
    }
  };

  useEffect(() => {
    const selectedVar = product.variations.find(
      (variation) => variation.storage === selectedStorage
    );

    setSelectedVariation(selectedVar);

    setSelectedPrice(getPriceFromVariation(selectedVar));
  }, [selectedStorage, selectedRentalPeriod, product.variations]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("storage", selectedStorage);
    newSearchParams.set("rentalPeriod", selectedRentalPeriod);
    newSearchParams.set("color", selectedColor);
    if (isLocked) newSearchParams.set("lock", "true");
    setSearchParams(newSearchParams);
  }, [selectedStorage, selectedRentalPeriod, selectedColor, setSearchParams]);

  const getUpfrontPaymentMessage = () => {
    switch (selectedRentalPeriod) {
      case "Weekly":
        return `Minimum subscription of 28 days for $${selectedVariation.pricePerWeek * 4} then`;
      case "Fortnightly":
        return `Minimum subscription of 28 days for $${
          selectedVariation.pricePerFortnight * 2
        } then`;
      default:
        return "";
    }
  };

  return (
    <div>
      <section className="py-12 sm:py-16">
        <div className="container mx-auto px-4">
          <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
            {/* //Carousel here           */}
            <div className="lg:col-span-3 lg:row-end-1">
              <div className="lg:flex lg:items-start">
                <div className="lg:order-2 lg:ml-5 xl:max-w-3xl lg:max-w-xl lg:px-8 md:px-16">
                  <ImageCarousel images={product.images} />
                </div>
              </div>
            </div>

            <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
              <h1 className="sm:text-2xl font-bold text-gray-900 sm:text-3xl">
                {product.title}
              </h1>
              {/* Conditionally render Upfront Payment Information */}
              {product.monthUpFront && (
                <div className="mt-4 text-gray-800">
                  <p>{getUpfrontPaymentMessage()}</p>
                </div>
              )}
              <h1 className="sm:text-1xl text-gray-900 sm:text-2xl mt-2">
                ${selectedPrice} {selectedRentalPeriod}
              </h1>

              <ColorCircles
                product={product}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                disabled={isLocked}
              />

              <div>
                <h2 className="mt-8 text-base text-gray-900">Storage</h2>
                <select
                  id="storage"
                  name="storage"
                  value={selectedStorage}
                  onChange={handleStorageChange}
                  disabled={isLocked}
                  className={`block w-full rounded-md border py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6 ${
                    isLocked ? disabledDropdownClass : ""
                  }`}
                >
                  {product.storage
                    .slice()
                    .sort(
                      (a, b) =>
                        parseInt(a.match(/\d+/)[0], 10) -
                        parseInt(b.match(/\d+/)[0], 10)
                    )
                    .map((storage, index) => (
                      <option key={index} value={storage}>
                        {storage}
                      </option>
                    ))}
                </select>
              </div>
              <h2 className="mt-8 text-base text-gray-900">
                Subscription Period
              </h2>
              <div className="mt-3">
                <select
                  id="Subscription-period"
                  name="Subscription-period"
                  value={selectedRentalPeriod}
                  onChange={handleRentalPeriodChange}
                  disabled={isLocked}
                  className={`block w-full rounded-md border py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6 ${
                    isLocked ? disabledDropdownClass : ""
                  }`}
                >
                  {product.rentalPeriods.map((rentalPeriod, index) => (
                    <option key={index} value={rentalPeriod}>
                      {rentalPeriod}
                    </option>
                  ))}
                </select>
              </div>

              {/* Rest of the product details */}
              <div className="mt-8 flex select-none flex-wrap items-center gap-1">
                <Link
                  to={link}
                  state={getSelectedProduct()}
                  className="block w-full rounded-md bg-primary-blue px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-blue"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mx-auto flex flex-wrap justify-center gap-4 py-8">
        <h1 className="text-4xl font-semibold text-gray-800 mb-4 px-8 ">
          Whats in the box
        </h1>
      </div>
      <div className="max-w-7xl mx-auto">
        <ImageView image={`data:image/png;base64,${product.inTheBox}`} />
      </div>
      <div className="mx-auto flex flex-wrap justify-center gap-4 py-8 grid">
        <h1 className="text-4xl font-semibold text-gray-800 mb-4 px-8 text-center">
          Description
        </h1>
        {product && product.description2 && (
          <div className="max-w-7xl mx-auto">
            <p className="text-gray-500 text-lg px-8">{product.description1}</p>
          </div>
        )}
        {product && product.description2 && (
          <div className="max-w-7xl mx-auto">
            <p className="text-gray-500 text-lg px-8">{product.description2}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDisplay;

const ImageCarousel = ({ images }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const settingsMain = {
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div>
      <Slider
        {...settingsMain}
        asNavFor={nav2}
        ref={(slider) => setNav1(slider)}
        className="slider-for"
      >
        {images.map((image, index) => (
          <div key={index} className="aspect-w-1 aspect-h-1">
            <img
              src={`data:image/png;base64,${image}`}
              alt={`iPhone ${index}`}
              className="object-scale-down max-h-full rounded-md m-auto"
            />
          </div>
        ))}
      </Slider>
      <Slider
        {...settingsThumbs}
        asNavFor={nav1}
        ref={(slider) => setNav2(slider)}
        className="slider-nav"
      >
        {images.map((image, index) => (
          <div key={index} className="aspect-w-1 aspect-h-1 ">
            <img
              src={`data:image/png;base64,${image}`}
              alt={`iPhone ${index}`}
              className="max-h-24 object-contain rounded-md m-auto"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
